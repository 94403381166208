import { Box, Grid } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

// Services
import { userSocialServices } from '../../../../services/users/userSocial/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import DetailCard from '../../../../components/cards/detailCard/DetailCard'

const UserSocialDetail = (props: { id: string }) => {
  const [data, setUserSocialData] = useState<any | null>(null)

  useEffect(() => {
    userSocialServices.findOne(props.id).then((response) => {
      setUserSocialData(response.data)
    })
  }, [])

  if (!data) {
    return <LoadingComponent />
  }

  return (
    <Box>
      <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
        <CustomInputForm children={<DetailCard upperText='Social Media' lowerText={data.socialMediaName} />} />
        <CustomInputForm children={<DetailCard upperText='Profile Link' lowerText={data.profileLink} />} />
        <CustomInputForm children={<DetailCard upperText='Account Name' lowerText={data.socialAccountName} />} />
        <CustomInputForm children={<DetailCard upperText='Follower Number' lowerText={data.followerNumber} />} />
      </Grid>
    </Box>
  )
}
export default UserSocialDetail
//
