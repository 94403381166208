import { GiLevelEndFlag } from 'react-icons/gi'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Grid } from '@chakra-ui/react'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { contractLevelServices } from '../../../services/contractLevels/index'

// Components
import UpdateDeleteButton from '../../../components/customFormElements/UpdateDeleteButton'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import DetailCard from '../../../components/cards/detailCard/DetailCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'

const ContractLevelDetail = () => {
  const [contractLevel, setContractLevelData] = useState<any | null>(null)
  const id = location.pathname.split('/contract-level/')[1]
  const navigate = useNavigate()

  useEffect(() => {
    contractLevelServices.findOne(id).then((response) => {
      setContractLevelData(response.data)
    })
  }, [])

  const deleteFunction = (id: string) => {
    contractLevelServices.remove(id).finally(() => {
      navigate('/contract-level')
    })
  }

  if (!contractLevel) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={GiLevelEndFlag} pageName='Contract Level' />
      <GeneralCard title='Contract Level Detail'>
        <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <DetailCard upperText='Name' lowerText={contractLevel.name} />
          <DetailCard upperText='Description' lowerText={contractLevel.description} />
          <DetailCard upperText='Level' lowerText={contractLevel.level} />
          <DetailCard upperText='Currency Type' lowerText={contractLevel.currencyType} />

          <DetailCard upperText='First Title' lowerText={contractLevel.firstTitle} />
          <DetailCard upperText='First Value' lowerText={contractLevel.firstValue} />
          <DetailCard upperText='Second Title' lowerText={contractLevel.secondTitle} />
          <DetailCard upperText='Second Value' lowerText={contractLevel.secondValue} />
          <DetailCard upperText='Third Title' lowerText={contractLevel.thirdTitle} />
          <DetailCard upperText='Third Value' lowerText={contractLevel.thirdValue} />
        </Grid>
      </GeneralCard>
      <UpdateDeleteButton deleteFunction={deleteFunction} id={id} navigateUrl={'/contract-level/update'} />
    </DefaultLayout>
  )
}

export default ContractLevelDetail
