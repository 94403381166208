import { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'

// Services
import { userContractServices } from '../../../../services/users/userContract/index'

// Components
import LoadingComponent from '../../../../components/shareds/LoadingComponent'

const UserContractReview = (props: { id: string }) => {
  const [userContractData, setUserContractData] = useState<any | null>(null)

  useEffect(() => {
    userContractServices.fillOutTemplateContract(props.id).then((response) => {
      setUserContractData(response.data)
    })
  }, [])

  if (!userContractData) {
    return <LoadingComponent />
  }

  return <Box width='100%' height='100%' overflow='auto' dangerouslySetInnerHTML={{ __html: userContractData }} />
}

export default UserContractReview
