import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { contractTemplateServices } from '../../services/contracts/contractTemplate/index'
interface ContractTemplateData {
  id: string
  name: string
  content: string
  version: number
  languageCode: string
}

interface ArrayObjectSelectState {
  selectedContractTemplate: ContractTemplateData | null
  setSelectedContractTemplate: (contractTemplate: ContractTemplateData | null) => void
  selectContractTemplate: string
}

const ContractTemplateSelect: React.FC<ArrayObjectSelectState> = ({
  selectedContractTemplate,
  setSelectedContractTemplate,
  selectContractTemplate,
}) => {
  const [countries, setContractTemplate] = useState<ContractTemplateData[]>([])

  useEffect(() => {
    const loadCountries = async () => {
      const response = await contractTemplateServices.findAll()
      if (response) {
        const contractTemplateData = response.data as ContractTemplateData[]
        setContractTemplate(
          contractTemplateData.map<ContractTemplateData>((item) => {
            return {
              id: item.id,
              name: item.name,
              content: item.content,
              version: item.version,
              languageCode: item.languageCode,
            }
          }),
        )
        if (selectContractTemplate) {
          const contractTemplate = contractTemplateData.find((item) => item.id === selectContractTemplate)
          if (contractTemplate) setSelectedContractTemplate(contractTemplate)
        }
      } else {
        toast('An error occurred while loading the Contract Template data.')
      }
    }
    loadCountries()
  }, [selectContractTemplate, setSelectedContractTemplate])

  return (
    <Select
      value={selectedContractTemplate}
      onChange={(option: ContractTemplateData | null) => {
        setSelectedContractTemplate(option)
      }}
      getOptionLabel={(option: ContractTemplateData) => option.name}
      getOptionValue={(option: ContractTemplateData) => option.id}
      options={countries}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'CONTRACT TEMPLATE'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default ContractTemplateSelect
