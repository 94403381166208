import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Box } from '@chakra-ui/react'
import Select from 'react-select'

import { socialMediaServices } from '../../services/socialMedias/index'
interface SocialMediaData {
  id: string
  name: string
  logo: string
}

interface ArrayObjectSelectState {
  selectedSocialMedia: SocialMediaData | null
  setSelectedSocialMedia: (socialMedia: SocialMediaData | null) => void
  selectSocialMedia?: string
}

const formatOptionLabel = (data: SocialMediaData) => (
  <Box display='flex' alignItems='center'>
    <img src={data.logo} alt={data.name} style={{ width: '20px', marginRight: '8px' }} />
    {data.name}
  </Box>
)

const SocialMediaSelect: React.FC<ArrayObjectSelectState> = ({
  selectedSocialMedia,
  setSelectedSocialMedia,
  selectSocialMedia,
}) => {
  const [socialMedias, setSocialMedia] = useState<SocialMediaData[]>([])

  useEffect(() => {
    const loadCountries = async () => {
      const response = await socialMediaServices.findAll()
      if (response) {
        const socialMediaData = response.data as SocialMediaData[]
        setSocialMedia(
          socialMediaData.map<SocialMediaData>((item) => {
            return {
              id: item.id,
              name: item.name,
              logo: item.logo,
            }
          }),
        )
        if (selectSocialMedia) {
          const socialMedia = socialMediaData.find((item) => item.name === selectSocialMedia)
          if (socialMedia) setSelectedSocialMedia(socialMedia)
        }
      } else {
        toast('An error occurred while loading the Socal Media data.')
      }
    }
    loadCountries()
  }, [selectSocialMedia, setSelectedSocialMedia])

  return (
    <Select
      value={selectedSocialMedia}
      onChange={(option: SocialMediaData | null) => {
        setSelectedSocialMedia(option)
      }}
      formatOptionLabel={formatOptionLabel}
      getOptionLabel={(option: SocialMediaData) => option.name}
      getOptionValue={(option: SocialMediaData) => option.id}
      options={socialMedias}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'SOCIAL MEDIA'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default SocialMediaSelect
