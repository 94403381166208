import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import LanguageSelect from '../../../components/selects/LanguageSelect'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { tagServices } from '../../../services/tags'
type TagFormData = {
  tag: string
  languageCode: string
}

const TagAdd = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<any | null>(null)
  const validationSchema = Yup.object().shape({
    tag: Yup.string().required('Tag is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TagFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: TagFormData) => {
    if (!selectedLanguage) {
      toast('Language is required')
      return
    }
    data.languageCode = selectedLanguage.code
    tagServices.create(data).then((response) => {
      if (response) window.location.reload()
    })
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            title='Tag'
            children={<Input type='text' id='tag' {...register('tag')} placeholder='Tag' />}
          />
          <CustomInputForm
            title='Language'
            children={
              <LanguageSelect selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} code='' />
            }
          />
        </Grid>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Add'} />
      </Flex>
    </>
  )
}

export default TagAdd
