import { Badge } from '@chakra-ui/react'
import React from 'react'

export enum PaymentType {
  Waiting = 'waiting',
  Paid = 'paid',
  Failed = 'failed',
  Cancelled = 'cancelled',
}

interface PaymentStatusBadgeProps {
  status: PaymentType
}

const PaymentStatusBadge: React.FC<PaymentStatusBadgeProps> = ({ status }) => {
  switch (status) {
    case PaymentType.Waiting:
      return <Badge colorScheme='orange' children={'Waiting'} />
    case PaymentType.Paid:
      return <Badge colorScheme='green' children={'Paid'} />
    case PaymentType.Failed:
      return <Badge colorScheme='red' children={'Failed'} />
    case PaymentType.Cancelled:
      return <Badge colorScheme='gray' children={'Cancelled'} />
    default:
      return <Badge children={'Default'} />
  }
}

export default PaymentStatusBadge
