import { Button, Input, Flex, Grid, Box, InputLeftAddon, InputGroup } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useState } from 'react'
import * as Yup from 'yup'

// Services
import { userPhoneServices } from '../../../../services/users/userPhone/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import CountrySelect from '../../../../components/selects/CountrySelect'

type UserPhoneFormData = {
  userId: string
  country: string
  countryCode: string
  phoneNumber: string
}

const UserPhoneCreate = () => {
  const [selectedCountry, setSelectedCountry] = useState<any | null>(null)
  const id = location.pathname.split('/influencer/')[1]

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required('Phone Number is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserPhoneFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: UserPhoneFormData) => {
    if (!data.countryCode && !selectedCountry?.alpha2Code) {
      toast('Country is required')
      return
    }

    data.countryCode = data.countryCode ?? selectedCountry?.alpha2Code
    data.country = data.country ?? selectedCountry?.name
    data.userId = id

    userPhoneServices.create(data).finally(() => {
      window.location.reload()
    })
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            title='Country'
            children={
              <CountrySelect
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectCountry=''
              />
            }
          />
          <CustomInputForm
            title='Phone Number'
            children={
              <InputGroup>
                <InputLeftAddon children={selectedCountry ? selectedCountry.callingCodes : ''} />
                <Input type='tel' id='phoneNumber' {...register('phoneNumber')} placeholder='Phone Number' />
              </InputGroup>
            }
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Save'} />
      </Flex>
    </Box>
  )
}
export default UserPhoneCreate
