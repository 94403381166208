import { Button, Input, Flex, Grid, Box, Textarea } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import * as Yup from 'yup'

// Services
import { userNoteServices } from '../../../../services/users/userNote/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import NoteTypeSelect from '../../../../components/selects/NoteTypeSelect'
import { toast } from 'react-toastify'

type UserNoteFormData = {
  userId: string
  title: string
  description: string
  noteType: string
}

const UserNoteCreate = () => {
  const [selectedNoteType, setSelectedNoteType] = useState<any | null>(null)
  const id = location.pathname.split('/influencer/')[1]

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserNoteFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: UserNoteFormData) => {
    if (!selectedNoteType) {
      toast('Note Type is required')
      return
    }

    data.noteType = selectedNoteType
    data.userId = id
    userNoteServices.create(data).finally(() => {
      window.location.reload()
    })
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            error={errors.title}
            title='Title'
            children={<Input type='text' id='title' {...register('title')} placeholder='Title' />}
          />

          <CustomInputForm
            error={errors.title}
            title='Note Type'
            children={<NoteTypeSelect selectedNoteType={selectedNoteType} setSelectedNoteType={setSelectedNoteType} />}
          />

          <CustomInputForm
            error={errors.title}
            customColSpan={4}
            title='Title'
            children={<Textarea id='description' {...register('description')} placeholder='Description' size={'sm'} />}
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Save'} />
      </Flex>
    </Box>
  )
}
export default UserNoteCreate
