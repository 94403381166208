import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useState } from 'react'
import * as Yup from 'yup'

import DefaultLayout from '../../../../layouts/DefaultLayout'

import { MdAccountBox } from 'react-icons/md'

// Services
import { userServices } from '../../../../services/users/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import CustomCheckbox from '../../../../components/customFormElements/CustomCheckbox'
import SocialMediaSelect from '../../../../components/selects/SocialMediaSelect'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import LanguageSelect from '../../../../components/selects/LanguageSelect'
import UserTypeSelect from '../../../../components/selects/UserTypeSelect'
import CountrySelect from '../../../../components/selects/CountrySelect'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

type UserFormData = {
  firstName: string
  lastName: string
  email: string
  mainPhoneNumber: string
  languageCode: string
  mainSocialMediaName: string
  mainSocialMediaType: string
  followerNumber: number
  isSpecial: false
  userType: string
  country: string
  countryCode: string
  postCode: string
  city: string
  state: string
  detail: string
  isMain: boolean
}

const UserCreate = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<any | null>(null)
  const [selectedUserType, setSelectedUserType] = useState<any | null>(null)
  const [selectedCountry, setSelectedCountry] = useState<any | null>(null)
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<any | null>(null)
  const [mainChecked, setMainChecked] = useState(false)
  const [specialChecked, setSpecialChecked] = useState(false)

  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mainPhoneNumber: Yup.string().required('Phone Number is required'),
    mainSocialMediaName: Yup.string().required('Social Media Name is required'),
    followerNumber: Yup.number().required('Follower Number is required'),
    isSpecial: Yup.boolean().required('Special is required'),
    postCode: Yup.string().required('Post Code is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    detail: Yup.string().required('Detail is required'),
    isMain: Yup.boolean().required('Main is required'),
  })

  const handleMainCheckedChange = (newCheckState: boolean) => {
    setMainChecked(newCheckState)
  }
  const handleSpecialCheckedChange = (newCheckState: boolean) => {
    setSpecialChecked(newCheckState)
  }
  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: UserFormData) => {
    const preparedData = {
      ...data,
      mainSocialMediaType: data.mainSocialMediaType || selectedSocialMedia.name,
      languageCode: data.languageCode || selectedLanguage.code,
      userType: data.userType || selectedUserType,
      countryCode: data.countryCode || selectedCountry.alpha2Code,
      country: data.country || selectedCountry.name,
    }

    userServices
      .create(preparedData)
      .then((response) => {
        if (response) {
          navigate(`/influencer/${response.data.id}`)
        }
      })
      .catch((error) => {
        toast(error.request)
      })
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdAccountBox} pageName='Influencer Create' />

      <form>
        <GeneralCard title='Add Influencer'>
          <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              error={errors.firstName}
              children={
                <Input bg={'white'} type='text' id='firstName' {...register('firstName')} placeholder='First Name' />
              }
            />
            <CustomInputForm
              error={errors.lastName}
              children={
                <Input bg={'white'} type='text' id='lastName' {...register('lastName')} placeholder='Last Name' />
              }
            />
            <CustomInputForm
              error={errors.email}
              children={<Input bg={'white'} type='email' id='email' {...register('email')} placeholder='Email' />}
            />
            <CustomInputForm
              error={errors.mainPhoneNumber}
              children={
                <Input
                  bg={'white'}
                  type='text'
                  id='mainPhoneNumber'
                  {...register('mainPhoneNumber')}
                  placeholder='Phone Number'
                />
              }
            />
            <CustomInputForm
              children={
                <LanguageSelect selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
              }
            />
            <CustomInputForm
              children={
                <UserTypeSelect selectedUserType={selectedUserType} setSelectedUserType={setSelectedUserType} />
              }
            />
            <CustomInputForm
              children={
                <CustomCheckbox
                  defaultChecked={specialChecked}
                  onCheckChange={handleSpecialCheckedChange}
                  name={'Special'}
                  register={register}
                  registerName='isSpecial'
                />
              }
            />
          </Grid>
        </GeneralCard>

        <Flex>
          <GeneralCard title='Add Main Address' customWidth='45%'>
            <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
              <CustomInputForm
                children={
                  <CountrySelect
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    selectCountry=''
                  />
                }
              />
              <CustomInputForm
                error={errors.postCode}
                children={<Input type='text' id='postCode' {...register('postCode')} placeholder='Post Code' />}
              />
              <CustomInputForm
                error={errors.city}
                children={<Input type='text' id='city' {...register('city')} placeholder='City' />}
              />
              <CustomInputForm
                error={errors.state}
                children={<Input type='text' id='state' {...register('state')} placeholder='State' />}
              />
              <CustomInputForm
                error={errors.detail}
                children={<Input type='text' id='detail' {...register('detail')} placeholder='Detail' />}
              />
              <CustomInputForm
                children={
                  <CustomCheckbox
                    defaultChecked={mainChecked}
                    onCheckChange={handleMainCheckedChange}
                    name={'Main'}
                    register={register}
                    registerName='isMain'
                  />
                }
              />
            </Grid>
          </GeneralCard>
          <GeneralCard title='Add Primary Account' customWidth='45%'>
            <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(2, 1fr)' gap={4} margin={'20px'}>
              <CustomInputForm
                children={
                  <SocialMediaSelect
                    selectedSocialMedia={selectedSocialMedia}
                    setSelectedSocialMedia={setSelectedSocialMedia}
                    selectSocialMedia={''}
                  />
                }
              />
              <CustomInputForm
                error={errors.mainSocialMediaName}
                children={
                  <Input
                    bg={'white'}
                    type='text'
                    id='mainSocialMediaName'
                    {...register('mainSocialMediaName')}
                    placeholder='Social Media Name'
                  />
                }
              />
              <CustomInputForm
                error={errors.followerNumber}
                children={
                  <Input
                    bg={'white'}
                    type='number'
                    id='followerNumber'
                    {...register('followerNumber')}
                    placeholder='Follower Number'
                  />
                }
              />
            </Grid>
          </GeneralCard>
        </Flex>
      </form>
      <Flex margin={'20px 0px 40px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Add'} />
      </Flex>
    </DefaultLayout>
  )
}

export default UserCreate
