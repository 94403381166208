import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import UserEmailUpdate from './update'
import UserEmailDetail from './detail'

// Services
import { userEmailServices } from '../../../services/users/userEmail/index'

// Components
import ActionButton from '../../../components/customFormElements/ActionButtons'
import CustomModal from '../../../components/modal/CustomModal'

const UserEmail = () => {
  const [userEmailData, setUserEmailsDataa] = useState<any[]>([])
  const [userEmailId, setUserEmailId] = useState<string>()

  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onClose: onCloseUpdate } = useDisclosure()
  const { isOpen: isOpenDetail, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure()

  const id = location.pathname.split('/influencer/')[1]

  useEffect(() => {
    if (!isOpenUpdate) {
      setUserEmailFunction()
    }
    userEmailServices.findByUserId(id).then((response) => {
      if (response) {
        setUserEmailsDataa(response.data)
      } else {
        toast('An error occurred while loading the User Email data.')
      }
    })
  }, [isOpenUpdate, id])

  const handleOnSubmit = (emailId: string) => {
    setUserEmailId(emailId)
    onOpenUpdate()
  }

  const detailFunction = (emailId: string) => {
    setUserEmailId(emailId)
    onOpenDetail()
  }

  const setUserEmailFunction = () => {
    userEmailServices.findByUserId(id).then((response) => {
      if (response) {
        setUserEmailsDataa(response.data)
      } else {
        toast('An error occurred while loading the User Email data.')
      }
    })
  }

  const deleteFunction = (id: string) => {
    userEmailServices.remove(id).finally(() => {
      setUserEmailFunction()
    })
  }

  return (
    <>
      <TableContainer maxH='20em' overflowY='auto'>
        <Table variant='simple' size={'sm'}>
          <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
            <Tr>
              <Th children={'Email'} />
              <Th children={'Created Date'} />
              <Th children={'Action'} />
            </Tr>
          </Thead>
          {userEmailData.map((item) => {
            return (
              <Tbody paddingBottom={'20px'} key={item.id}>
                <Tr>
                  <Td
                    onClick={() => detailFunction(item.id)}
                    cursor={'pointer'}
                    color={'blue'}
                    children={<Link children={item.email} />}
                  />
                  <Td children={item.createdDate.slice(0, 10)} />
                  <Td
                    children={
                      <ActionButton deleteFunction={deleteFunction} id={item.id} updateFunction={handleOnSubmit} />
                    }
                  />
                </Tr>
              </Tbody>
            )
          })}
        </Table>
      </TableContainer>
      {
        <CustomModal
          title='User Email Update'
          isOpen={isOpenUpdate}
          onClose={onCloseUpdate}
          key={userEmailId}
          children={<UserEmailUpdate id={userEmailId ? userEmailId : ''} closeModal={onCloseUpdate} />}
        />
      }
      {
        <CustomModal
          title='User Email Detail'
          isOpen={isOpenDetail}
          onClose={onCloseDetail}
          key={'User_Email_Detail'}
          children={<UserEmailDetail id={userEmailId ? userEmailId : ''} />}
        />
      }
    </>
  )
}

export default UserEmail
