import { Box, Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

// Services
import { userEmailServices } from '../../../../services/users/userEmail/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'

type UserEmailFormData = {
  userId: string
  email: string
}

const UserEmailUpdate = (props: { id: string; closeModal: () => void }) => {
  const [userEmail, setUserEmailData] = useState<any | null>(null)
  const userId = location.pathname.split('/influencer/')[1]

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
  })

  useEffect(() => {
    userEmailServices.findOne(props.id).then((response) => {
      if (response) {
        setUserEmailData(response.data)
      }
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmailData({
      ...userEmail,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserEmailFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: UserEmailFormData) => {
    data.userId = userId

    userEmailServices.update(props.id, data).finally(() => {
      props.closeModal()
    })
  }

  if (!userEmail) {
    return <LoadingComponent />
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(2, 1fr)' gap={2} margin={'20px'}>
          <CustomInputForm
            error={errors.email}
            title='Email'
            children={
              <Input
                type='text'
                id='email'
                {...register('email')}
                placeholder='Email'
                value={userEmail.email}
                onChange={handleInputChange}
              />
            }
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </>
  )
}
export default UserEmailUpdate
