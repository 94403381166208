import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { userServices } from '../../services/users/index'
interface UserData {
  id: string
  firstName: string
  lastName: string
  email: string
  mainSocialMediaName: string
}

interface ArrayObjectSelectState {
  selectUser?: UserData | null
  selectedUser: UserData | null
  setSelectedUser: (user: UserData | null) => void
  id: string
  placeHolderText: string
}

const UserSelect: React.FC<ArrayObjectSelectState> = ({ selectedUser, setSelectedUser, id, placeHolderText }) => {
  const [users, setUser] = useState<UserData[]>([])

  useEffect(() => {
    const loadCountries = async () => {
      const response = await userServices.findAll()
      if (response) {
        const userData = response.data as UserData[]
        setUser(
          userData.map<UserData>((item) => {
            return {
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
              email: item.email,
              mainSocialMediaName: item.mainSocialMediaName,
            }
          }),
        )
        if (id) {
          const user = userData.find((item) => item.id === id)

          if (user) setSelectedUser(user)
        }
      } else {
        toast('An error occurred while loading the User data.')
      }
    }
    loadCountries()
  }, [id, setSelectedUser])

  return (
    <Select
      value={selectedUser}
      onChange={(option: UserData | null) => {
        setSelectedUser(option)
      }}
      getOptionLabel={(option: UserData) => option.firstName + ' ' + option.lastName}
      getOptionValue={(option: UserData) => option.id}
      options={users}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={placeHolderText}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default UserSelect
