import {
  useColorModeValue,
  MenuDivider,
  IconButton,
  MenuButton,
  FlexProps,
  MenuItem,
  MenuList,
  HStack,
  Avatar,
  VStack,
  Flex,
  Menu,
  Text,
  Box,
} from '@chakra-ui/react'
import { useGlobalContext } from '../providers/globalProvider'
import { FiBell, FiChevronDown, FiMenu } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { getEmployee as getEmployeeLocal } from '../utils/local-storage'

import SearchSelect from '../components/search/GeneralSearch'

interface MobileProps extends FlexProps {
  onOpen: () => void
}

const SearchBar = ({ onOpen, ...rest }: MobileProps) => {
  const { user, logout } = useGlobalContext()
  const employeeData = getEmployeeLocal()
  const navigate = useNavigate()

  const handleClick = () => {
    logout()
    navigate('/login')
  }
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='20'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <SearchSelect />
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant='outline'
        aria-label='open menu'
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize='2xl'
        fontFamily='monospace'
        fontWeight='bold'
        color='red'
        children={'SOCIAI'}
      />

      <HStack spacing={{ base: '0', md: '6' }}>
        <IconButton size='lg' variant='ghost' aria-label='open menu' icon={<FiBell />} />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar size={'md'} />
                <VStack display={{ base: 'none', md: 'flex' }} alignItems='flex-start' spacing='1px' ml='2'>
                  <Text fontSize='sm' children={employeeData.name} />
                  <Text
                    fontSize='xs'
                    color='gray.600'
                    children={
                      employeeData.roles.includes('superAdmin')
                        ? 'SuperAdmin'
                        : employeeData.roles.includes('admin')
                          ? 'Admin'
                          : 'Employee'
                    }
                  />
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }} children={<FiChevronDown />} />
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem children={'Profile'} />
              <MenuItem children={'Settings'} />
              <MenuItem children={'Billing'} />
              <MenuDivider />
              <MenuItem onClick={handleClick} children={'Sign out'} />
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

export default SearchBar
