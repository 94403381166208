import { withLoggedIn, withLoggedOut } from './withLoggedIn'
import AccessDenied from '../pages/error/AccessDenied'
import Dashboard from '../pages/dashboard/dashboard'
import Login from '../pages/auth/login'

import { Route, Routes } from 'react-router-dom'

// User
import User from '../pages/users/users'
import UserDetail from '../pages/users/users/detail/index'
import UserCreate from '../pages/users/users/create/index'
import UserUpdate from '../pages/users/users/update/index'
// import UserContractSign from '../pages/users/userContracts/contractSign/index'
// import Congratulation from '../pages/users/userContracts/congratulation/index'

// Employee
import Employee from '../pages/manageEmployees/index'
import EmployeeDetail from '../pages/manageEmployees/detail/index'
import EmployeeCreate from '../pages/manageEmployees/create/index'
import EmployeeUpdate from '../pages/manageEmployees/update/index'

// Project
import Project from '../pages/projects/index'
import ProjectDetail from '../pages/projects/detail/index'
import ProjectCreate from '../pages/projects/create/index'
import ProjectUpdate from '../pages/projects/update/index'

// Language
import Language from '../pages/languages/index'
import LanguageDetail from '../pages/languages/detail/index'
import LanguageCreate from '../pages/languages/create/index'
import LanguageUpdate from '../pages/languages/update/index'

// SocialMedia
import SocialMedia from '../pages/socialMedias/index'
import SocialMediaDetail from '../pages/socialMedias/detail/index'
import SocialMediaCreate from '../pages/socialMedias/create/index'
import SocialMediaUpdate from '../pages/socialMedias/update/index'

// ContractLevel
import ContractLevel from '../pages/contractLevels/index'
import ContractLevelDetail from '../pages/contractLevels/detail/index'
import ContractLevelCreate from '../pages/contractLevels/create/index'
import ContractLevelUpdate from '../pages/contractLevels/update/index'

// Message Template
import MailTemplate from '../pages/mailTemplates/index'
import MailTemplateDetail from '../pages/mailTemplates/detail/index'
import MailTemplateCreate from '../pages/mailTemplates/create/index'
import MailTemplateUpdate from '../pages/mailTemplates/update/index'

// Message Template
import ContractTemplate from '../pages/contractTemplates/index'
import ContractTemplateDetail from '../pages/contractTemplates/detail/index'
import ContractTemplateCreate from '../pages/contractTemplates/create/index'
import ContractTemplateUpdate from '../pages/contractTemplates/update/index'

// Calendar
import Calendar from '../pages/calendar'
import ContentBank from '../pages/contentBank'
import ContentTag from '../pages/contentTag'
import Tag from '../pages/tag'
import ContentDetail from '../pages/contentBank/detail'

export function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={withLoggedIn(Dashboard)()} />
      <Route path='/login' element={withLoggedOut(Login)()} />
      <Route path='/dashboard' element={withLoggedIn(Dashboard)()} />
      <Route path='/access-denied' element={withLoggedIn(AccessDenied)()} />
      <Route path='*' element={withLoggedIn(Dashboard)()} />

      <Route path='/influencer' element={withLoggedIn(User)()} />
      <Route path='/influencer/:id' element={withLoggedIn(UserDetail)()} />
      <Route path='/influencer/update/:id' element={withLoggedIn(UserUpdate)()} />
      <Route path='/influencer/add' element={UserCreate()} />

      <Route path='/manage-employee' element={withLoggedIn(Employee)()} />
      <Route path='/manage-employee/:id' element={withLoggedIn(EmployeeDetail)()} />
      <Route path='/manage-employee/add' element={withLoggedIn(EmployeeCreate)()} />
      <Route path='/manage-employee/update/:id' element={withLoggedIn(EmployeeUpdate)()} />

      <Route path='/project' element={withLoggedIn(Project)()} />
      <Route path='/project/:id' element={withLoggedIn(ProjectDetail)()} />
      <Route path='/project/add' element={withLoggedIn(ProjectCreate)()} />
      <Route path='/project/update/:id' element={withLoggedIn(ProjectUpdate)()} />

      <Route path='/language' element={withLoggedIn(Language)()} />
      <Route path='/language/:id' element={withLoggedIn(LanguageDetail)()} />
      <Route path='/language/add' element={withLoggedIn(LanguageCreate)()} />
      <Route path='/language/update/:id' element={withLoggedIn(LanguageUpdate)()} />

      <Route path='/social-media' element={withLoggedIn(SocialMedia)()} />
      <Route path='/social-media/:id' element={withLoggedIn(SocialMediaDetail)()} />
      <Route path='/social-media/add' element={withLoggedIn(SocialMediaCreate)()} />
      <Route path='/social-media/update/:id' element={withLoggedIn(SocialMediaUpdate)()} />

      <Route path='/contract-level' element={withLoggedIn(ContractLevel)()} />
      <Route path='/contract-level/:id' element={withLoggedIn(ContractLevelDetail)()} />
      <Route path='/contract-level/add' element={withLoggedIn(ContractLevelCreate)()} />
      <Route path='/contract-level/update/:id' element={withLoggedIn(ContractLevelUpdate)()} />

      <Route path='/mail-template' element={withLoggedIn(MailTemplate)()} />
      <Route path='/mail-template/:id' element={withLoggedIn(MailTemplateDetail)()} />
      <Route path='/mail-template/add' element={withLoggedIn(MailTemplateCreate)()} />
      <Route path='/mail-template/update/:id' element={withLoggedIn(MailTemplateUpdate)()} />

      <Route path='/contract-template' element={withLoggedIn(ContractTemplate)()} />
      <Route path='/contract-template/:id' element={withLoggedIn(ContractTemplateDetail)()} />
      <Route path='/contract-template/add' element={withLoggedIn(ContractTemplateCreate)()} />
      <Route path='/contract-template/update/:id' element={withLoggedIn(ContractTemplateUpdate)()} />

      <Route path='/calendar' element={withLoggedIn(Calendar)()} />
      <Route path='/content-bank' element={withLoggedIn(ContentBank)()} />
      <Route path='/content-bank/:id' element={withLoggedIn(ContentDetail)()} />
      <Route path='/content-bank/tag' element={withLoggedIn(Tag)()} />
      <Route path='/content-bank/content-tag' element={withLoggedIn(ContentTag)()} />
    </Routes>
  )
}
