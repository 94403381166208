import { Button, Box, Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

interface CustomAlertDialogProps {
  deleteFunction: (firstId: string) => void
  id: string
  navigateUrl: string
}

const UpdateDeleteButton: React.FC<CustomAlertDialogProps> = ({ deleteFunction, id, navigateUrl }) => {
  const navigate = useNavigate()
  return (
    <Box>
      <Flex margin={'20px 0px 20px 40px'}>
        <Button
          colorScheme='green'
          size={'lg'}
          minWidth={'100px'}
          onClick={() => navigate(`${navigateUrl}/${id}`)}
          marginRight={'20px'}
          children={'Update'}
        />
        <Button
          colorScheme='red'
          size={'lg'}
          minWidth={'100px'}
          onClick={() => deleteFunction(id)}
          children={'Delete'}
        />
      </Flex>
    </Box>
  )
}

export default UpdateDeleteButton
