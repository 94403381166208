import { useEffect, useState } from 'react'

// Icons
import { FaUserPlus } from 'react-icons/fa6'
import { FaFileSignature, FaFileAlt, FaStar, FaShareSquare } from 'react-icons/fa'
import { MdDashboard, MdOutlineEventBusy, MdCancelPresentation } from 'react-icons/md'

import DefaultLayout from '../../layouts/DefaultLayout'
import { getToken } from '../../utils/local-storage'

// Services
import { userServices } from '../../services/users'

// Components
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../components/shareds/LoadingComponent'
import NavCard from '../../components/cards/navCard/NavigateCard'
import MidCard from '../../components/cards/midCard/MidCard'

const Dashboard = () => {
  const [statusData, setStatus] = useState<any | null>(null)

  // useEffect(() => {
  //   const token = getToken()
  //   if (token) {
  //     userServices.statusList().then((response) => {
  //       if (response.data) {
  //         setStatus(response.data)
  //       }
  //     })
  //   }
  // }, [])

  // if (!statusData) {
  //   return <LoadingComponent />
  // }

  return (
    <DefaultLayout>
      <NavCard icon={MdDashboard} pageName='Dashboard' key={'Dashboard'} />
      {/* <GeneralCard title='STATS' customColumn={{ base: 1, md: 2, lg: 3, '2xl': 4 }}>
        <MidCard
          icon={FaUserPlus}
          name={'New Leads'}
          value={statusData[11].total}
          link={'/influencers?contractStatus=registered'}
          key={statusData[10]}
        />
        <MidCard
          icon={FaFileSignature}
          name={'Contract Signed'}
          value={statusData[3].total}
          link={'/influencers?contractStatus=signed'}
          key={statusData[2].total}
        />
        <MidCard
          icon={FaFileAlt}
          name={'Contract Not Signed'}
          value={statusData[5].total}
          link={'/influencers?contractStatus=notSigned'}
          key={statusData[4].total}
        />
        <MidCard
          icon={MdOutlineEventBusy}
          name={'Expired Contracts'}
          value={statusData[13].total}
          link={'/influencers?contractStatus=expired'}
          key={statusData[12].total}
        />
        <MidCard
          icon={MdCancelPresentation}
          name={'Cancelled Contracts'}
          value={statusData[7].total}
          link={'/influencers?contractStatus=cancelled'}
          key={statusData[6].total}
        />
        <MidCard
          icon={FaShareSquare}
          name={'Referred'}
          value={statusData[9].total}
          link={'/influencers?contractStatus=referred'}
          key={statusData[8].total}
        />
        <MidCard
          icon={FaStar}
          name={'Special Contracts'}
          value={statusData[15].total}
          link={'/influencers?contractStatus=special'}
          key={statusData[14].total}
        />
      </GeneralCard> */}
    </DefaultLayout>
  )
}

export default Dashboard
