import React from 'react'
import Select, { SingleValue } from 'react-select'

interface DataSelectProps<T> {
  selectedValue: T | null
  setSelectedValue: React.Dispatch<React.SetStateAction<T | null>>
  data: T[] // Veriyi dışarıdan gönderiyoruz
  getOptionLabel: (item: T) => string
  getOptionValue: (item: T) => string
  placeholder?: string
}

const DataSelect = <T extends NonNullable<unknown>>({
  selectedValue,
  setSelectedValue,
  data,
  getOptionLabel,
  getOptionValue,
  placeholder = 'Select...',
}: DataSelectProps<T>) => {
  const options = data.map((item) => ({
    value: item,
    label: getOptionLabel(item),
  }))

  const handleChange = (option: SingleValue<{ value: T; label: string }>) => {
    setSelectedValue(option ? option.value : null)
  }

  return (
    <Select
      value={
        selectedValue
          ? {
              value: selectedValue,
              label: getOptionLabel(selectedValue),
            }
          : null
      }
      onChange={handleChange}
      options={options}
      isClearable={true}
      placeholder={placeholder}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default DataSelect
