import { axiosInstance } from '../axios'

export const contentBankServices = {
  create: (mediaType: string, projectId: string, requestModel: any) => {
    return axiosInstance.post(`content-bank/contents?mediaType=${mediaType}&projectId=${projectId}`, requestModel, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  findAll: (type: string, isOrganic: string, page: number, limit: number) => {
    return axiosInstance.get(`content-bank/contents?type=${type}&isOrganic=${isOrganic}&page=${page}&limit=${limit}`)
  },
  findOne: (id: string) => {
    return axiosInstance.get(`content-bank/contents/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`content-bank/contents/${id}`)
  },
}
