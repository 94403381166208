import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { MdWork } from 'react-icons/md'
import { toast } from 'react-toastify'

import DefaultLayout from '../../layouts/DefaultLayout'

// Services
import { mailTemplateServices } from '../../services/mailTemplates/index'

// Components
import ActionButton from '../../components/customFormElements/ActionButtons'
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import NavCard from '../../components/cards/navCard/NavigateCard'
import ProjectFilter from '../../components/generalFilter/ProjectFilter'

const MailTemplate = () => {
  const [mailTemplateData, setMailTemplatesData] = useState<any[]>([])
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const projectId =
      selectedProject && selectedProject.id && selectedProject.id.length > 1
        ? selectedProject.id
        : 'b20cc4b4-f68b-40d3-abf4-69cb4ef31052'

    mailTemplateServices
      .findByProjectId(projectId)
      .then((response) => {
        if (response && response.data) {
          setMailTemplatesData(response.data)
        } else {
          toast('An error occurred while loading the Mail Template data.')
        }
      })
      .catch((error) => {
        console.error('Error fetching mail templates:', error)
        toast('An error occurred while loading the Mail Template data.')
      })
  }, [selectedProject])

  const updateMailTemplate = (id: string) => {
    navigate(`/mail-template/update/${id}`)
  }

  const detailMailTemplate = (id: string) => {
    navigate(`/mail-template/${id}`)
  }

  const deleteMailTemplate = (id: string) => {
    mailTemplateServices.remove(id).finally(() => {
      window.location.reload()
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdWork} pageName='Mail Template' secondButton='Add Mail Template' secondButtonLink='add' />
      <GeneralCard
        title=''
        children={<ProjectFilter setSearchInput={setInputValue} setSelectedProject={setSelectedProject} />}
      />

      <GeneralCard title='Mail Template List' customColumn={{ base: 1, md: 1, lg: 1, '2xl': 1 }}>
        <TableContainer>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'type'} />
                <Th children={'name'} />
                <Th children={'fromMail'} />
                <Th children={'subject'} />
                <Th children={'language Code'} />
                <Th children={'ACTION'} />
              </Tr>
            </Thead>
            {mailTemplateData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td onClick={() => detailMailTemplate(item.id)} cursor={'pointer'} children={item.type} />
                    <Td children={item.name} />
                    <Td children={item.fromMail} />
                    <Td children={item.subject} />
                    <Td children={item.languageCode} />
                    <Td
                      children={
                        <ActionButton
                          deleteFunction={deleteMailTemplate}
                          id={item.id}
                          updateFunction={updateMailTemplate}
                        />
                      }
                    />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default MailTemplate
