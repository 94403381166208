import { Button, Box, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import CustomAlertDialog from '../dialog/AlertDialog'
import { ChevronDownIcon } from '@chakra-ui/icons'

interface CustomAlertDialogProps {
  deleteFunction: (firstId: string) => void
  id: string
  updateFunction?: (firstId: string) => void
}

const ActionButton: React.FC<CustomAlertDialogProps> = ({ deleteFunction, id, updateFunction }) => {
  return (
    <Box>
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} children={'Actions'} />
        <MenuList>
          <CustomAlertDialog confirmAction={deleteFunction} id={id} />
          {updateFunction && (
            <MenuItem onClick={() => updateFunction(id)} cursor={'pointer'} color={'green'} children={'Update'} />
          )}
        </MenuList>
      </Menu>
    </Box>
  )
}

export default ActionButton
