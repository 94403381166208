import { Button, Input, Flex, Grid } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

// Services
import { calendarServices } from '../../../services/calendars/index'

// Components
import DateAndTimePicker from '../../../components/customFormElements/DateAndTimePicker'
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import { CalendarStatus } from '../../../enums/calendar-status'
import UserSelect from '../../../components/selects/UserSelect'
import EnumSelect from '../../../components/selects/EnumSelect'
import { TaskType } from '../../../enums/task-type'
import SocialMediaSelect from '../../../components/selects/SocialMediaSelect'
import { getEmployee as getEmployeeLocal } from '../../../utils/local-storage'

type CalendarFormData = {
  userId: string
  contentId: string
  title: string
  summary: string
  description: string
  taskType: string
  socialMediaType: string
  status: string
  reason: string
  startDate: Date
  endDate: Date
  employeeId: string
}

const CalendarUpdate = (props: { id: string }) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null)
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<any | null>(null)
  const [selectedCalendarStatus, setSelectedCalendarStatus] = useState<any | null>(null)
  const [selectedTaskType, setSelectedTaskType] = useState<any | null>(null)
  const [calendarData, setSelectedCalendarData] = useState<any | null>(null)
  const [user, setUser] = useState<any | null>(null)
  const employeeData = getEmployeeLocal()

  useEffect(() => {
    calendarServices.findOne(props.id).then((response) => {
      if (response) {
        setSelectedCalendarData(response.data)

        if (selectedStartDate === null) {
          setSelectedStartDate(new Date(response.data.startDate))
        }
        if (selectedStartDate === response.data.startDate) {
          setSelectedStartDate(new Date(response.data.startDate))
        }
        if (selectedEndDate === null) {
          setSelectedEndDate(new Date(response.data.endDate))
        }
        if (selectedEndDate === response.data.endDate) {
          setSelectedEndDate(new Date(response.data.endDate))
        }
      }
    })
  }, [])

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    summary: Yup.string().required('Summary is required'),
    description: Yup.string().required('Description is required'),
    reason: Yup.string().required('Reason is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CalendarFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCalendarData({
      ...calendarData,
      [event.target.name]: event.target.value,
    })
  }

  const onSubmit = (data: CalendarFormData) => {
    if (!data.startDate && !selectedStartDate?.toLocaleString()) {
      toast('Start Date is required')
      return
    }
    if (!data.endDate && !selectedEndDate?.toLocaleString()) {
      toast('End Date is required')
      return
    }
    if (!data.socialMediaType && !selectedSocialMedia?.toLocaleString()) {
      toast('Social Media Type is required')
      return
    }
    if (!data.userId && !user.id.toLocaleString()) {
      toast('User is required')
      return
    }

    data.startDate = data.startDate ?? selectedStartDate?.toISOString()
    data.endDate = data.endDate ?? selectedEndDate?.toISOString()
    data.socialMediaType = selectedSocialMedia.name.toString()
    data.status = selectedCalendarStatus.toString()
    data.taskType = selectedTaskType.toString()
    data.employeeId = employeeData.sub.toString()
    data.userId = user.id.toString()

    calendarServices
      .update(props.id, data)
      .then((response) => {
        if (response) {
          window.location.reload()
        }
      })
      .catch((error) => {
        toast(error.request)
      })
  }

  const deleteCalendarEvent = (id: string) => {
    calendarServices.remove(id).finally(() => {
      window.location.reload()
    })
  }

  if (!calendarData) {
    return <LoadingComponent />
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            title='User'
            children={
              <UserSelect
                selectedUser={user}
                setSelectedUser={setUser}
                placeHolderText='User'
                id={calendarData.userId}
              />
            }
          />
          <CustomInputForm
            error={errors.title}
            title='Title'
            children={
              <Input
                type='text'
                id='title'
                {...register('title')}
                placeholder='Title'
                value={calendarData.title}
                onChange={handleInputChange}
              />
            }
          />
          <CustomInputForm
            error={errors.summary}
            title='Summary'
            children={
              <Input
                type='text'
                id='summary'
                {...register('summary')}
                placeholder='Summary'
                value={calendarData.summary}
                onChange={handleInputChange}
              />
            }
          />
          <CustomInputForm
            error={errors.description}
            title='Description'
            children={
              <Input
                type='text'
                id='description'
                {...register('description')}
                placeholder='Description'
                value={calendarData.description}
                onChange={handleInputChange}
              />
            }
          />
          <CustomInputForm
            error={errors.description}
            title='Reason'
            children={
              <Input
                type='text'
                id='reason'
                {...register('reason')}
                placeholder='Reason'
                value={calendarData.reason}
                onChange={handleInputChange}
              />
            }
          />
          <CustomInputForm
            error={errors.taskType}
            title='Task Type'
            children={
              <EnumSelect
                selectedValue={selectedTaskType}
                selectValue={calendarData.taskType}
                setSelectedValue={setSelectedTaskType}
                enumType={TaskType}
                placeholder='Task Type'
              />
            }
          />
          <CustomInputForm
            title='Select Social Media'
            children={
              <SocialMediaSelect
                selectedSocialMedia={selectedSocialMedia}
                setSelectedSocialMedia={setSelectedSocialMedia}
                selectSocialMedia={calendarData.socialMediaType}
              />
            }
          />
          <CustomInputForm
            error={errors.status}
            title='Status'
            children={
              <EnumSelect
                selectedValue={selectedCalendarStatus}
                selectValue={calendarData.status}
                setSelectedValue={setSelectedCalendarStatus}
                enumType={CalendarStatus}
                placeholder='Status'
              />
            }
          />
          <CustomInputForm
            customColSpan={1}
            error={errors.startDate}
            children={<DateAndTimePicker selectedDate={selectedStartDate} setSelectedDate={setSelectedStartDate} />}
            title='Start Date'
          />
          <CustomInputForm
            customColSpan={1}
            error={errors.endDate}
            children={<DateAndTimePicker selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate} />}
            title='End Date'
          />
        </Grid>
      </form>
      <Flex width={'full'} justifyContent='space-between'>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Save'} />
        <Button colorScheme='red' size={'sm'} onClick={() => deleteCalendarEvent(props.id)} children={'Delete'} />
      </Flex>
    </>
  )
}
export default CalendarUpdate
