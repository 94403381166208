import { useEffect, useState } from 'react'
import { Grid } from '@chakra-ui/react'

// Services
import { userEmailServices } from '../../../../services/users/userEmail/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import DetailCard from '../../../../components/cards/detailCard/DetailCard'

const UserEmailDetail = (props: { id: string }) => {
  const [userEmailData, setUserEmailData] = useState<any | null>(null)

  useEffect(() => {
    userEmailServices.findOne(props.id).then((response) => {
      setUserEmailData(response.data)
    })
  }, [])

  if (!userEmailData) {
    return <LoadingComponent />
  }

  return (
    <>
      <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(2, 1fr)' gap={4} margin={'20px'}>
        <CustomInputForm children={<DetailCard upperText='Email' lowerText={userEmailData.email} />} />
      </Grid>
    </>
  )
}
export default UserEmailDetail
