import React, { useEffect } from 'react'
import Select from 'react-select'

import { UserType } from '../../enums/user-type'

interface ArrayObjectSelectState {
  selectedUserType: UserType | null
  setSelectedUserType: (userType: UserType | null) => void
  type?: string
}

const UserTypeSelect: React.FC<ArrayObjectSelectState> = ({ selectedUserType, setSelectedUserType, type }) => {
  const [userTypes, setUserTypes] = React.useState<UserType[]>([])

  useEffect(() => {
    setUserTypes(Object.values(UserType))
  }, [])

  useEffect(() => {
    if (type && userTypes.length > 0) {
      const userType = userTypes.find((item) => item === type)
      if (userType) setSelectedUserType(userType)
    }
  }, [type, userTypes])

  return (
    <Select
      value={
        selectedUserType
          ? {
              value: selectedUserType,
              label: selectedUserType?.toUpperCase(),
            }
          : null
      }
      onChange={(option: any) => {
        setSelectedUserType(option ? option.value : null)
      }}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      options={userTypes.map((type) => ({
        value: type,
        label: type.toUpperCase(),
      }))}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'TYPE'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default UserTypeSelect
