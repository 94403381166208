export const setEmployee = (employee) => localStorage.setItem('USERDATA', JSON.stringify(employee))
export const getEmployee = () => JSON.parse(localStorage.getItem('USERDATA'))
export const removeUser = () => localStorage.removeItem('USERDATA')

export const setToken = (token) => localStorage.setItem('TOKENDATA', token)
export const getToken = () => localStorage.getItem('TOKENDATA')
export const removeToken = () => localStorage.removeItem('TOKENDATA')

export const setExpiresIn = (token) => localStorage.setItem('EXPIRESINDATA', token)
export const getExpiresIn = () => localStorage.getItem('EXPIRESINDATA')
export const removeExpiresIn = () => localStorage.removeItem('EXPIRESINDATA')
