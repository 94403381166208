import React, { useEffect } from 'react'
import Select from 'react-select'

import { CalendarStatus } from '../../enums/calendar-status'

interface ArrayObjectSelectState {
  selectedCalendarStatus: CalendarStatus | null
  setSelectedCalendarStatus: (calendarStatus: CalendarStatus | null) => void
  status?: string
}

const CalendarStatusSelect: React.FC<ArrayObjectSelectState> = ({
  selectedCalendarStatus,
  setSelectedCalendarStatus,
  status,
}) => {
  const [calendarStatuss, setCalendarStatuss] = React.useState<CalendarStatus[]>([])

  useEffect(() => {
    setCalendarStatuss(Object.values(CalendarStatus))

    if (status) {
      const calendarStatus = calendarStatuss.find((item) => item === status)
      if (calendarStatus) setSelectedCalendarStatus(calendarStatus)
    }
  }, [status, setCalendarStatuss])

  return (
    <Select
      value={
        selectedCalendarStatus
          ? {
              value: selectedCalendarStatus,
              label: selectedCalendarStatus?.toUpperCase(),
            }
          : null
      }
      onChange={(option: any) => {
        setSelectedCalendarStatus(option ? option.value : null)
      }}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      options={calendarStatuss.map((status) => ({
        value: status,
        label: status.toUpperCase(),
      }))}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'STATUS'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default CalendarStatusSelect
