import React, { useEffect } from 'react'
import Select from 'react-select'

interface EnumSelectProps<T> {
  selectedValue: T | null
  selectValue: T | null
  setSelectedValue: (value: T | null) => void
  enumType: any
  placeholder?: string
}

const EnumSelect = <T extends string | number>({
  selectedValue,
  selectValue,
  setSelectedValue,
  enumType,
  placeholder = 'SELECT',
}: EnumSelectProps<T>) => {
  const [options, setOptions] = React.useState<{ value: T; label: string }[]>([])

  useEffect(() => {
    const enumValues = Object.values(enumType).filter((value) => typeof value === 'string') as T[]
    const enumOptions = enumValues.map((value) => ({
      value,
      label: value.toString().toUpperCase(),
    }))
    setOptions(enumOptions)

    if (selectValue) {
      if (selectValue.toString().length > 3) {
        if (selectValue) setSelectedValue(selectValue)
      }
    }
  }, [enumType, setSelectedValue])

  return (
    <Select
      value={
        selectedValue
          ? {
              value: selectedValue,
              label: selectedValue.toString().toUpperCase(),
            }
          : null
      }
      onChange={(option: any) => {
        setSelectedValue(option ? option.value : null)
      }}
      options={options}
      isClearable={true}
      placeholder={placeholder}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default EnumSelect
