import { Box, Icon, Text } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { styles } from './styles'

export default function Hero(props: { icon: IconType; label: string }) {
  return (
    <Box sx={styles.hero}>
      <Box sx={styles.icon} children={<Icon color={'white'} fontSize='24' as={props.icon} />} />
      <Text sx={styles.label} children={props.label} />
    </Box>
  )
}
