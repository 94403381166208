import { Button, Flex, Grid, GridItem, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { FaLanguage } from 'react-icons/fa'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { mailTemplateServices } from '../../../services/mailTemplates/index'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'
import LanguageSelect from '../../../components/selects/LanguageSelect'
import MailTypeSelect from '../../../components/selects/MailTypeSelect'
import ProjectSelect from '../../../components/selects/ProjectSelect'
import { Editor } from '@tinymce/tinymce-react'

type MailTemplateFormData = {
  type: string
  name: string
  content: string
  languageCode: string
  fromMail: string
  subject: string
  projectId: string
}

const MailTemplateUpdate = () => {
  const [mailTemplateData, setMailTemplateData] = useState<any | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<any | null>(null)
  const [selectedMailType, setSelectedMailType] = useState<any | null>(null)
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [editorData, setEditorData] = useState<any>()

  const navigate = useNavigate()

  const id = location.pathname.split('/mail-template/update/')[1]

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    fromMail: Yup.string().required('From Mail is required'),
    subject: Yup.string().required('Subject is required'),
  })

  useEffect(() => {
    mailTemplateServices.findOne(id).then((response) => {
      if (response) {
        setMailTemplateData(response.data)
        setSelectedMailType(response.data.type)
        setEditorData(response.data.content)
      }
    })
  }, [])

  const handleEditorChange = (content: string, editor: any) => {
    setEditorData(content)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMailTemplateData({
      ...mailTemplateData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<MailTemplateFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: MailTemplateFormData) => {
    data.type = selectedMailType
    data.languageCode = selectedLanguage.code
    data.projectId = selectedProject.id
    data.content = editorData
    mailTemplateServices.update(id, data).finally(() => {
      navigate('/mail-template')
    })
  }

  if (!mailTemplateData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={FaLanguage} pageName='MailTemplate' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Update MailTemplate'>
          <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(6, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              title='Project'
              children={
                <ProjectSelect
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  id={mailTemplateData.projectId}
                />
              }
            />
            <CustomInputForm
              title='Type'
              children={
                <MailTypeSelect
                  selectedMailType={selectedMailType}
                  setSelectedMailType={setSelectedMailType}
                  type={mailTemplateData.type}
                />
              }
            />
            <CustomInputForm
              title='Language'
              children={
                <LanguageSelect
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  code={mailTemplateData.languageCode}
                />
              }
            />
            <CustomInputForm
              title='Name'
              error={errors.name}
              children={
                <Input
                  type='text'
                  id='name'
                  {...register('name')}
                  value={mailTemplateData.name}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='From Mail'
              customColSpan={3}
              error={errors.fromMail}
              children={
                <Input
                  type='text'
                  id='fromMail'
                  {...register('fromMail')}
                  value={mailTemplateData.fromMail}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Subject'
              customColSpan={3}
              error={errors.subject}
              children={
                <Input
                  type='text'
                  id='subject'
                  {...register('subject')}
                  value={mailTemplateData.subject}
                  onChange={handleInputChange}
                />
              }
            />
            <GridItem
              colSpan={6}
              children={
                <Editor
                  apiKey='uhsd8cs3nsvcr8l0t1nwi8ix65n1p3mb21708bl77jr8wh9d'
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    height: 900,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'code',
                    ],

                    toolbar:
                      'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                  }}
                  value={editorData ? editorData : ''}
                  onEditorChange={handleEditorChange}
                />
              }
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </DefaultLayout>
  )
}
export default MailTemplateUpdate
