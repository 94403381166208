import { axiosInstance } from '../axios'

export const contentTagServices = {
  create: (requestModel: any) => {
    return axiosInstance.post(`content-bank/content-tags`, requestModel)
  },
  findAll: (contentId: string, tagId: string, page: number, limit: number) => {
    return axiosInstance.get(
      `content-bank/content-tags?contentId=${contentId}&tagId=${tagId}&&page=${page}&limit=${limit}`,
    )
  },
  remove: (id: string) => {
    return axiosInstance.delete(`content-bank/content-tags/${id}`)
  },
}
