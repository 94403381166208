import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import React from 'react'

interface DatePickerComponentProps {
  selectedDate: Date | null
  setSelectedDate: (date: Date | null) => void
}

const DateAndTimePicker: React.FC<DatePickerComponentProps> = ({ selectedDate, setSelectedDate }) => {
  return (
    <DatePicker
      selected={selectedDate instanceof Date ? selectedDate : null}
      onChange={(date: Date | null, event?: React.SyntheticEvent<any>) => setSelectedDate(date)}
      dateFormat='dd/MM/yyyy hh:mm'
      isClearable
      placeholderText='Select Date'
      showTimeSelect
      timeCaption='Select Time'
      timeIntervals={30}
    />
  )
}

export default DateAndTimePicker
