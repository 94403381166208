import { Stack, Switch, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

// Services
import { userAddressServices } from '../../../services/users/userAddress/index'

import UserAddressUpdate from './update'
import UserAddressDetail from './detail'

// Components
import ActionButton from '../../../components/customFormElements/ActionButtons'
import CustomModal from '../../../components/modal/CustomModal'

const UserAddress = () => {
  const [userAddressData, setUserAddressData] = useState<any[]>([])
  const [userAddressId, setUserAddressId] = useState<string>()

  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onClose: onCloseUpdate } = useDisclosure()
  const { isOpen: isOpenDetail, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure()

  const id = location.pathname.split('/influencer/')[1]

  useEffect(() => {
    if (!isOpenUpdate) {
      setUserAddress()
    }
    userAddressServices.findByUserId(id).then((response) => {
      if (response) {
        setUserAddressData(response.data)
      } else {
        toast('An error occurred while loading the User Address data.')
      }
    })
  }, [isOpenUpdate, id])

  const setUserAddress = () => {
    userAddressServices.findByUserId(id).then((response) => {
      if (response) {
        setUserAddressData(response.data)
      } else {
        toast('An error occurred while loading the User Address data.')
      }
    })
  }

  const updateUserAddress = (addressId: string) => {
    setUserAddressId(addressId)
    onOpenUpdate()
  }

  const detailUserAddress = (addressId: string) => {
    setUserAddressId(addressId)
    onOpenDetail()
  }

  const deleteUserAddress = (id: string) => {
    userAddressServices.remove(id).finally(() => {
      setUserAddress()
    })
  }

  const changeSwitch = (itemId: string, newState: boolean) => {
    const requestModel = {
      userId: id,
      boolValue: newState,
    }

    userAddressServices.updateMain(itemId, requestModel).finally(() => {
      setUserAddress()
    })
  }

  return (
    <>
      <TableContainer maxH='20em' overflowY='auto'>
        <Table variant='simple' size={'sm'}>
          <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
            <Tr>
              <Th children={'Country'} />
              <Th children={'State'} />
              <Th children={'City'} />
              <Th children={'Main'} />
              <Th children={'Action'} />
            </Tr>
          </Thead>
          {userAddressData.map((item) => {
            return (
              <Tbody paddingBottom={'20px'} key={item.id}>
                <Tr>
                  <Td
                    onClick={() => detailUserAddress(item.id)}
                    cursor={'pointer'}
                    color={'blue'}
                    children={<Link children={item.country} />}
                  />
                  <Td children={item.state} />
                  <Td children={item.city} />
                  <Td
                    children={
                      <Stack direction='row'>
                        <Switch
                          colorScheme='teal'
                          size='lg'
                          isChecked={item.isMain}
                          onChange={(e) => changeSwitch(item.id, e.target.checked)}
                        />
                      </Stack>
                    }
                  />
                  <Td
                    children={
                      <ActionButton
                        deleteFunction={deleteUserAddress}
                        id={item.id}
                        updateFunction={updateUserAddress}
                      />
                    }
                  />
                </Tr>
              </Tbody>
            )
          })}
        </Table>
      </TableContainer>
      {
        <CustomModal
          title='User Address Update'
          isOpen={isOpenUpdate}
          onClose={onCloseUpdate}
          key={userAddressId}
          children={<UserAddressUpdate id={userAddressId ? userAddressId : ''} closeModal={onCloseUpdate} />}
        />
      }
      {
        <CustomModal
          title='User Address Detail'
          isOpen={isOpenDetail}
          onClose={onCloseDetail}
          key={'User_Address_Detail'}
          children={<UserAddressDetail id={userAddressId ? userAddressId : ''} />}
        />
      }
    </>
  )
}

export default UserAddress
