import { ContractStatus } from '../../enums/contract-status'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { userServices } from '../../services/users'
interface ArrayObjectSelectState {
  userId: string
  status: string
}

const ContractStatusSelect: React.FC<ArrayObjectSelectState> = ({ userId, status }) => {
  const [contractStatus, setContractStatus] = React.useState<ContractStatus[]>([])
  const [selectedContractStatus, setSelectedContractStatus] = useState<ContractStatus | null>(null)

  const updateContractStatus = (id: string, option: ContractStatus | null) => {
    setSelectedContractStatus(option)
    const requestModel = {
      contractStatus: option,
    }

    userServices.updateStatus(id, requestModel)
  }

  useEffect(() => {
    const contractStatusData = Object.values(ContractStatus)

    setContractStatus(contractStatusData)
    const result = contractStatusData.find((item) => item === status)
    if (result) {
      setSelectedContractStatus(result)
    }
  }, [userId])

  return (
    <Select
      value={
        selectedContractStatus
          ? {
              value: selectedContractStatus,
              label: selectedContractStatus?.toUpperCase(),
            }
          : null
      }
      onChange={(option: any) => {
        updateContractStatus(userId, option ? option.value : null)
      }}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      options={contractStatus.map((item) => ({
        value: item,
        label: item.toUpperCase(),
      }))}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder='CONTRACT STATUS'
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default ContractStatusSelect
