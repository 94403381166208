import { axiosInstance } from '../axios'

export const tagServices = {
  create: (requestModel: any) => {
    return axiosInstance.post(`content-bank/tags`, requestModel)
  },
  findAll: (languageCode: string, page: number, limit: number) => {
    return axiosInstance.get(`content-bank/tags/${languageCode}?&page=${page}&limit=${limit}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`content-bank/tags/${id}`)
  },
}
