import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import UserSocialUpdate from './update'
import UserSocialDetail from './detail'

// Services
import { userSocialServices } from '../../../services/users/userSocial/index'

// Components
import ActionButton from '../../../components/customFormElements/ActionButtons'
import CustomModal from '../../../components/modal/CustomModal'

const UserSocial = () => {
  const [userSocialData, setUserSocialsData] = useState<any[]>([])
  const [userSocialId, setUserSocialId] = useState<string>()

  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onClose: onCloseUpdate } = useDisclosure()
  const { isOpen: isOpenDetail, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure()

  const id = location.pathname.split('/influencer/')[1]

  useEffect(() => {
    if (!isOpenUpdate) {
      setUserSocial()
    }
    userSocialServices.findByUserId(id).then((response) => {
      if (response) {
        setUserSocialsData(response.data)
      } else {
        toast('An error occurred while loading the User Social data.')
      }
    })
  }, [isOpenUpdate, id])

  const setUserSocial = () => {
    userSocialServices.findByUserId(id).then((response) => {
      if (response) {
        setUserSocialsData(response.data)
      } else {
        toast('An error occurred while loading the User Social data.')
      }
    })
  }

  const updateUserSocial = (socialId: string) => {
    setUserSocialId(socialId)
    onOpenUpdate()
  }

  const detailUserSocial = (socialId: string) => {
    setUserSocialId(socialId)
    onOpenDetail()
  }

  const deleteUserSocial = (id: string) => {
    userSocialServices.remove(id).finally(() => {
      setUserSocial()
    })
  }

  return (
    <>
      <TableContainer maxH='20em' overflowY='auto'>
        <Table variant='simple' size={'sm'}>
          <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
            <Tr>
              <Th children={'Profile Link'} />
              <Th children={'Social Account Name'} />
              <Th children={'Follower Number'} />
              <Th children={'Action'} />
            </Tr>
          </Thead>
          {userSocialData.map((item) => {
            return (
              <Tbody paddingBottom={'20px'} key={item.id}>
                <Tr>
                  <Td
                    onClick={() => detailUserSocial(item.id)}
                    cursor={'pointer'}
                    color={'blue'}
                    children={<Link children={item.profileLink} />}
                  />
                  <Td children={item.socialAccountName} />
                  <Td children={item.followerNumber} />
                  <Td
                    children={
                      <ActionButton deleteFunction={deleteUserSocial} id={item.id} updateFunction={updateUserSocial} />
                    }
                  />
                </Tr>
              </Tbody>
            )
          })}
        </Table>
      </TableContainer>
      {
        <CustomModal
          title='User Social Update'
          isOpen={isOpenUpdate}
          onClose={onCloseUpdate}
          key={userSocialId}
          children={<UserSocialUpdate id={userSocialId ? userSocialId : ''} closeModal={onCloseUpdate} />}
        />
      }
      {
        <CustomModal
          title='User Social Detail'
          isOpen={isOpenDetail}
          onClose={onCloseDetail}
          key={'User_Social_Detail'}
          children={<UserSocialDetail id={userSocialId ? userSocialId : ''} />}
        />
      }
    </>
  )
}

export default UserSocial
