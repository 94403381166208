import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Box, Grid, GridItem } from '@chakra-ui/react'

import DefaultLayout from '../../../layouts/DefaultLayout'

import { MdQuestionAnswer } from 'react-icons/md'

// Services
import { mailTemplateServices } from '../../../services/mailTemplates'

// Components
import UpdateDeleteButton from '../../../components/customFormElements/UpdateDeleteButton'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import DetailCard from '../../../components/cards/detailCard/DetailCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'

const MailTemplateDetail = () => {
  const [mailTemplateData, setMailTemplateData] = useState<any | null>(null)

  const id = location.pathname.split('/mail-template/')[1]
  const navigate = useNavigate()

  useEffect(() => {
    mailTemplateServices.findOne(id).then((response) => {
      setMailTemplateData(response.data)
    })
  }, [])

  const deleteFunction = (id: string) => {
    mailTemplateServices.remove(id).finally(() => {
      navigate('/mail-template')
    })
  }

  if (!mailTemplateData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdQuestionAnswer} pageName='Mail Template' />

      <GeneralCard title='Mail Template Detail'>
        <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(4, 1fr)' gap={2} margin={'20px'}>
          <DetailCard upperText='Type' lowerText={mailTemplateData.type} />
          <DetailCard upperText='Language Code' lowerText={mailTemplateData.languageCode} />

          <DetailCard upperText='Subject' lowerText={mailTemplateData.subject} />
          <DetailCard upperText='From Mail' lowerText={mailTemplateData.fromMail} />

          <GridItem colSpan={4}>
            <Box
              width='100%'
              height='100%'
              overflow='auto'
              dangerouslySetInnerHTML={{
                __html: mailTemplateData ? mailTemplateData.content : '',
              }}
            />
          </GridItem>
        </Grid>
      </GeneralCard>

      <UpdateDeleteButton deleteFunction={deleteFunction} id={id} navigateUrl='/mail-template/update' />
    </DefaultLayout>
  )
}

export default MailTemplateDetail
