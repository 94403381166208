import {
  getToken as getTokenLocal,
  setToken as setTokenLocal,
  getEmployee as getEmployeeLocal,
  setEmployee as setEmployeeLocal,
  removeToken as removeTokenLocal,
  removeUser as removeUserLocal,
  getExpiresIn as getExpiresInLocal,
  removeExpiresIn as removeExpiresInLocal,
  setExpiresIn as setExpiresInLocal,
} from '../utils/local-storage'
import { axiosInstance } from '../services/axios/index'
import { useEffect, useState, createContext, useContext, ReactNode } from 'react'
import * as decode from 'jwt-decode'

interface User {
  exp: number
  [key: string]: any
}

interface LoginParams {
  email: string
  password: string
}

interface GlobalContextType {
  user: User | null
  isExpired: () => boolean
  checkAuth: () => boolean
  login: (params: LoginParams) => Promise<void>
  logout: () => Promise<void>
  isLogin: boolean
}

interface GlobalProviderProps {
  children: ReactNode
}

const GlobalContext = createContext<GlobalContextType | undefined>(undefined)

export const useGlobalContext = (): GlobalContextType => {
  const context = useContext(GlobalContext)
  if (!context) throw new Error('useGlobalContext must be used within GlobalProvider')
  return context
}

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const [token, setToken] = useState<string | null>(getTokenLocal())
  const [user, setEmployee] = useState<User | null>(getEmployeeLocal())
  const [expiresIn, setExpiresIn] = useState<number | null>(
    getExpiresInLocal() ? parseInt(getExpiresInLocal() as string, 10) : null,
  )

  const login = async ({ email, password }: LoginParams): Promise<void> => {
    try {
      const response = await axiosInstance.post('/auth/login', { email, password })

      if (response.data) {
        const { accessToken } = response.data
        setToken(accessToken)
        setTokenLocal(accessToken)

        const decoded: User = decode.jwtDecode(accessToken)
        setEmployee(decoded)
        setEmployeeLocal(decoded)
        setExpiresIn(decoded.exp)
        setExpiresInLocal(decoded.exp)
      }
    } catch (ex) {
      console.log(ex)
    }
  }

  const logout = async (): Promise<void> => {
    try {
      setToken(null)
      removeTokenLocal()
      setEmployee(null)
      removeUserLocal()
      setExpiresIn(null)
      removeExpiresInLocal()
    } catch (ex) {
      console.log(ex)
    }
  }

  const isExpired = (): boolean => !(expiresIn && !isSessionExpired(expiresIn))

  const checkAuth = (): boolean => !!(token && !isExpired())

  let isLogin = false
  if (token && !isExpired()) {
    isLogin = true
  }

  const value: GlobalContextType = { user, isExpired, checkAuth, login, logout, isLogin }

  useEffect(() => {
    const init = () => {}
    init()
  }, [token, user, expiresIn])

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}

const isSessionExpired = (expiresIn: number | null): boolean => {
  if (!expiresIn) return true
  const now = Math.floor(Date.now() / 1000)
  return now >= expiresIn
}
