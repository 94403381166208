export const styles = {
  hero: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingTop: '10px',
    maxHeight: '90%',
    maxWidth: '90%',
  },
  icon: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '45px',
    height: '45px',
    borderRadius: '6px',
    backgroundColor: '#E02B26',
  },
  label: {
    color: '#E02B26',
    fontSize: '22px',
    fontWeight: 600,
    marginLeft: '10px',
  },
}
