import DefaultLayout from '../../layouts/DefaultLayout'

// Components
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import NavCard from '../../components/cards/navCard/NavigateCard'
import GalleryComponent from '../../components/gallery/GalleryComponent'
import { FaImage } from 'react-icons/fa6'
import CustomModal from '../../components/modal/CustomModal'
import { useDisclosure } from '@chakra-ui/react'
import ContentAdd from './create'

const ContentBank = () => {
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure()

  return (
    <DefaultLayout>
      <NavCard icon={FaImage} pageName='Content Bank' secondButton='Add Content' secondClickAction={onOpenAdd} />
      <GeneralCard title=''>
        <GalleryComponent />
      </GeneralCard>
      <CustomModal title='Add Content' isOpen={isOpenAdd} onClose={onCloseAdd} children={<ContentAdd />} />
    </DefaultLayout>
  )
}

export default ContentBank
