import { Button, Flex, Grid, GridItem, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { FaLanguage } from 'react-icons/fa'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { contractTemplateServices } from '../../../services/contracts/contractTemplate/index'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'
import LanguageSelect from '../../../components/selects/LanguageSelect'
import ContractTemplateTypeSelect from '../../../components/selects/ContractTemplateTypeSelect'
import ProjectSelect from '../../../components/selects/ProjectSelect'
import { Editor } from '@tinymce/tinymce-react'

type ContractTemplateFormData = {
  type: string
  name: string
  content: string
  languageCode: string
  description: string
  projectId: string
}

const ContractTemplateUpdate = () => {
  const [contractTemplateData, setContractTemplateData] = useState<any | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<any | null>(null)
  const [selectedContractTemplateType, setSelectedContractTemplateType] = useState<any | null>(null)
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [editorData, setEditorData] = useState<any>()

  const navigate = useNavigate()

  const id = location.pathname.split('/contract-template/update/')[1]

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
  })

  useEffect(() => {
    contractTemplateServices.findOne(id).then((response) => {
      if (response) {
        setContractTemplateData(response.data)
        setSelectedContractTemplateType(response.data.type)
        setEditorData(response.data.content)
      }
    })
  }, [])

  const handleEditorChange = (content: string, editor: any) => {
    setEditorData(content)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContractTemplateData({
      ...contractTemplateData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ContractTemplateFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: ContractTemplateFormData) => {
    data.type = selectedContractTemplateType
    data.languageCode = selectedLanguage.code
    data.projectId = selectedProject.id
    data.content = editorData
    contractTemplateServices.update(id, data).finally(() => {
      navigate('/contract-template')
    })
  }

  if (!contractTemplateData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={FaLanguage} pageName='Contract Template' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Update Contract Template'>
          <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(6, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              title='Project'
              children={
                <ProjectSelect
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  id={contractTemplateData.projectId}
                />
              }
            />
            <CustomInputForm
              title='Type'
              children={
                <ContractTemplateTypeSelect
                  selectedContractTemplateType={selectedContractTemplateType}
                  setSelectedContractTemplateType={setSelectedContractTemplateType}
                  type={contractTemplateData.type}
                />
              }
            />
            <CustomInputForm
              title='Language'
              children={
                <LanguageSelect
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  code={contractTemplateData.languageCode}
                />
              }
            />
            <CustomInputForm
              title='Name'
              error={errors.name}
              children={
                <Input
                  type='text'
                  id='name'
                  {...register('name')}
                  value={contractTemplateData.name}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Description'
              customColSpan={3}
              error={errors.description}
              children={
                <Input
                  type='text'
                  id='description'
                  {...register('description')}
                  value={contractTemplateData.description}
                  onChange={handleInputChange}
                />
              }
            />
            <GridItem
              colSpan={6}
              children={
                <Editor
                  apiKey='uhsd8cs3nsvcr8l0t1nwi8ix65n1p3mb21708bl77jr8wh9d'
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    height: 900,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'code',
                    ],

                    toolbar:
                      'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                  }}
                  value={editorData ? editorData : ''}
                  onEditorChange={handleEditorChange}
                />
              }
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </DefaultLayout>
  )
}
export default ContractTemplateUpdate
