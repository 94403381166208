import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Grid } from '@chakra-ui/react'

import DefaultLayout from '../../../layouts/DefaultLayout'

import { MdQuestionAnswer } from 'react-icons/md'

// Services
import { socialMediaServices } from '../../../services/socialMedias/index'

// Components
import UpdateDeleteButton from '../../../components/customFormElements/UpdateDeleteButton'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import DetailCard from '../../../components/cards/detailCard/DetailCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'

const SocialMediaDetail = () => {
  const [socialMediaData, setSocialMediaData] = useState<any | null>(null)

  const id = location.pathname.split('/social-media/')[1]
  const navigate = useNavigate()

  useEffect(() => {
    socialMediaServices.findOne(id).then((response) => {
      setSocialMediaData(response.data)
    })
  }, [])

  const deleteSocialMedia = (id: string) => {
    socialMediaServices.remove(id).finally(() => {
      navigate('/socialMedia')
    })
  }

  if (!socialMediaData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdQuestionAnswer} pageName='Social Media' />

      <GeneralCard title='Social Media Detail'>
        <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <DetailCard upperText='Name' lowerText={socialMediaData.name} />
          <DetailCard upperText='Code' lowerText={socialMediaData.code} />
          <DetailCard upperText='Logo URL' lowerText={socialMediaData.logo} />
          <DetailCard upperText='Order Number' lowerText={socialMediaData.orderNumber} />
          <DetailCard
            upperText='Min Follower Count For Level One'
            lowerText={socialMediaData.minFollowerCountForLevelOne}
          />
          <DetailCard
            upperText='Max Follower Count For Level One'
            lowerText={socialMediaData.maxFollowerCountForLevelOne}
          />
          <DetailCard
            upperText='Min Follower Count For Level Two'
            lowerText={socialMediaData.minFollowerCountForLevelTwo}
          />
          <DetailCard
            upperText='Max Follower Count For Level Two'
            lowerText={socialMediaData.maxFollowerCountForLevelTwo}
          />
          <DetailCard
            upperText='Min Follower Count For Level Three'
            lowerText={socialMediaData.minFollowerCountForLevelThree}
          />
          <DetailCard upperText='Min Engagement Rate' lowerText={socialMediaData.minEngagementRate} />
        </Grid>
      </GeneralCard>

      <UpdateDeleteButton deleteFunction={deleteSocialMedia} id={id} navigateUrl='/social-media/update' />
    </DefaultLayout>
  )
}

export default SocialMediaDetail
