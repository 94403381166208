import { Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import DefaultLayout from '../../layouts/DefaultLayout'
import ActionButton from '../../components/customFormElements/ActionButtons'
import CustomModal from '../../components/modal/CustomModal'
import { useEffect, useState } from 'react'
import { tagServices } from '../../services/tags'
import NavigateCard from '../../components/cards/navCard/NavigateCard'
import { AiOutlineTags } from 'react-icons/ai'
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import TagAdd from './create'

const Tag = () => {
  const [tagData, setTagData] = useState<any[]>([])
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure()

  useEffect(() => {
    tagServices.findAll('en', 1, 10).then((response) => {
      setTagData(response.data.items)
    })
  }, [])

  const deleteTag = (tagId: string) => {
    tagServices.remove(tagId).then(() => {
      window.location.reload()
    })
  }
  return (
    <DefaultLayout>
      <NavigateCard icon={AiOutlineTags} pageName='Tag' secondButton='Add Tag' secondClickAction={onOpenAdd} />
      <GeneralCard title=''>
        <TableContainer maxH='20em' overflowY='auto'>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'Tag'} />
                <Th children={'Language'} />
                <Th children={'Created Date'} />
                <Th children={'Action'} />
              </Tr>
            </Thead>
            {tagData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td children={<Link children={item.tag} />} />
                    <Td children={item.tag} />
                    <Td children={item.tag.slice(0, 10)} />
                    <Td children={<ActionButton deleteFunction={() => deleteTag(item.id)} id={item.id} />} />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
      <CustomModal title='Add Tag' isOpen={isOpenAdd} onClose={onCloseAdd} children={<TagAdd />} />
    </DefaultLayout>
  )
}

export default Tag
