import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import UserNoteUpdate from './update'
import UserNoteDetail from './detail'

// Services
import { userNoteServices } from '../../../services/users/userNote/index'

// Components
import CustomModal from '../../../components/modal/CustomModal'
import ActionButton from '../../../components/customFormElements/ActionButtons'

const UserNote = () => {
  const [userNoteData, setUserNotesData] = useState<any[]>([])
  const [userNoteId, setUserNoteId] = useState<string>()

  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onClose: onCloseUpdate } = useDisclosure()
  const { isOpen: isOpenDetail, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure()

  const id = location.pathname.split('/influencer/')[1]

  useEffect(() => {
    if (!isOpenUpdate) {
      setUserNote()
    }
    userNoteServices.findByUserId(id).then((response) => {
      if (response) {
        setUserNotesData(response.data)
      } else {
        toast('An error occurred while loading the User Note data.')
      }
    })
  }, [isOpenUpdate, id])

  const setUserNote = () => {
    userNoteServices.findByUserId(id).then((response) => {
      if (response) {
        setUserNotesData(response.data)
      } else {
        toast('An error occurred while loading the User Note data.')
      }
    })
  }

  const updateUserPhone = (noteId: string) => {
    setUserNoteId(noteId)
    onOpenUpdate()
  }

  const detailUserNote = (noteId: string) => {
    setUserNoteId(noteId)
    onOpenDetail()
  }

  const deleteUserNote = (id: string) => {
    userNoteServices.remove(id).finally(() => {
      setUserNote()
    })
  }

  return (
    <>
      <TableContainer maxH='20em' overflowY='auto'>
        <Table variant='simple' size={'sm'}>
          <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
            <Tr>
              <Th children={'Title'} />
              <Th children={'Description'} />
              <Th children={'Note Type'} />
              <Th children={'Created Date'} />
              <Th children={'Action'} />
            </Tr>
          </Thead>
          {userNoteData.map((item) => {
            return (
              <Tbody paddingBottom={'20px'} key={item.id}>
                <Tr>
                  <Td
                    onClick={() => detailUserNote(item.id)}
                    cursor={'pointer'}
                    color={'blue'}
                    children={<Link children={item.title} />}
                  />
                  <Td children={item.description} />
                  <Td color={item.color} children={item.noteType} />
                  <Td children={item.createdDate.slice(0, 10)} />
                  <Td
                    children={
                      <ActionButton deleteFunction={deleteUserNote} id={item.id} updateFunction={updateUserPhone} />
                    }
                  />
                </Tr>
              </Tbody>
            )
          })}
        </Table>
      </TableContainer>
      {
        <CustomModal
          title='User Note Update'
          isOpen={isOpenUpdate}
          onClose={onCloseUpdate}
          key={userNoteId}
          children={<UserNoteUpdate id={userNoteId ? userNoteId : ''} closeModal={onCloseUpdate} />}
        />
      }
      {
        <CustomModal
          title='User Note Detail'
          isOpen={isOpenDetail}
          onClose={onCloseDetail}
          key={'User_Note_Detail'}
          children={<UserNoteDetail id={userNoteId ? userNoteId : ''} />}
        />
      }
    </>
  )
}

export default UserNote
