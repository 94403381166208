import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useRef } from 'react'

interface CustomAlertDialogProps {
  confirmAction: (id: string) => void
  id: string
}

const CustomAlertDialog: React.FC<CustomAlertDialogProps> = ({ confirmAction, id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <MenuItem color='red' onClick={onOpen} children={'Delete'} />

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold' children={'Delete'} />
            <AlertDialogBody children={`Are you sure? You can't undo this action afterwards.`} />

            <AlertDialogFooter>
              <Button
                colorScheme='red'
                onClick={() => {
                  confirmAction(id)
                  onClose()
                }}
                children={'Delete'}
              />
              <Button ref={cancelRef} onClick={onClose} ml={3} children={'Cancel'} />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default CustomAlertDialog
