import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { FaLanguage } from 'react-icons/fa'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { projectServices } from '../../../services/projects/index'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import CustomCheckbox from '../../../components/customFormElements/CustomCheckbox'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'

type ProjectFormData = {
  name: string
  title: string
  description: string
  number: string
  address: number
  person: string
  email: string
  logoUrl: string
  isTermsheet: boolean
  isDefault: boolean
  establishedDate: string
}

const ProjectUpdate = () => {
  const [projectData, setProjectData] = useState<any | null>(null)
  const [termsheetChecked, setTermsheetChecked] = useState(false)
  const [defaultChecked, setDefaultChecked] = useState(false)
  const navigate = useNavigate()

  const id = location.pathname.split('/project/update/')[1]

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    number: Yup.string().required('Number is required'),
    address: Yup.string().required('Address is required'),
    person: Yup.string().required('Person is required'),
    email: Yup.string().required('E-mail is required'),
    logoUrl: Yup.string().required('Logo URL is required'),
    isTermsheet: Yup.boolean().required('Termsheet is required'),
    isDefault: Yup.boolean().required('Default is required'),
    establishedDate: Yup.string().required('Established Date is required'),
  })

  useEffect(() => {
    projectServices.findOne(id).then((response: any) => {
      if (response) {
        setProjectData(response.data)
        setTermsheetChecked(response.data.isTermsheet)
        setDefaultChecked(response.data.isDefault)
      }
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectData({
      ...projectData,
      [event.target.name]: event.target.value,
    })
  }

  const handleTermsheetChange = (newCheckState: boolean) => {
    setTermsheetChecked(newCheckState)
  }

  const handleDefaultChange = (newCheckState: boolean) => {
    setDefaultChecked(newCheckState)
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ProjectFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: ProjectFormData) => {
    projectServices.update(id, data).finally(() => {
      navigate('/project')
    })
  }

  if (!projectData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={FaLanguage} pageName='Project' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Update Project'>
          <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(6, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              error={errors.name}
              title='Name'
              children={
                <Input
                  type='text'
                  id='name'
                  {...register('name')}
                  value={projectData.name}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              error={errors.title}
              title='Title'
              children={
                <Input
                  type='text'
                  id='title'
                  {...register('title')}
                  value={projectData.title}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              error={errors.description}
              title='Description'
              children={
                <Input
                  type='text'
                  id='description'
                  {...register('description')}
                  value={projectData.description}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              error={errors.number}
              title='Number'
              children={
                <Input
                  type='text'
                  id='number'
                  {...register('number')}
                  value={projectData.number}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              error={errors.address}
              title='Address'
              children={
                <Input
                  type='text'
                  id='address'
                  {...register('address')}
                  value={projectData.address}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              error={errors.person}
              title='Person'
              children={
                <Input
                  type='text'
                  id='person'
                  {...register('person')}
                  value={projectData.person}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              error={errors.email}
              title='E-mail'
              children={
                <Input
                  type='text'
                  id='email'
                  {...register('email')}
                  value={projectData.email}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              error={errors.logoUrl}
              title='Logo URL'
              children={
                <Input
                  type='text'
                  id='logoUrl'
                  {...register('logoUrl')}
                  value={projectData.logoUrl}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              error={errors.establishedDate}
              title='Established Date'
              children={
                <Input
                  type='text'
                  id='establishedDate'
                  {...register('establishedDate')}
                  value={projectData.establishedDate.slice(0, 10)}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Termsheet'
              children={
                <CustomCheckbox
                  defaultChecked={termsheetChecked}
                  onCheckChange={handleTermsheetChange}
                  name={'Termsheet'}
                  register={register}
                  registerName='isTermsheet'
                />
              }
            />
            <CustomInputForm
              title='Default'
              children={
                <CustomCheckbox
                  defaultChecked={defaultChecked}
                  onCheckChange={handleDefaultChange}
                  name={'Default'}
                  register={register}
                  registerName='isDefault'
                />
              }
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </DefaultLayout>
  )
}
export default ProjectUpdate
