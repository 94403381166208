import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { MdWork } from 'react-icons/md'
import { toast } from 'react-toastify'

import DefaultLayout from '../../layouts/DefaultLayout'

// Services
import { socialMediaServices } from '../../services/socialMedias/index'

// Components
import ActionButton from '../../components/customFormElements/ActionButtons'
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import NavCard from '../../components/cards/navCard/NavigateCard'

const SocialMedia = () => {
  const [socialMediaData, setSocialMediasData] = useState<any[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    socialMediaServices.findAll().then((response: any) => {
      if (response) setSocialMediasData(response.data)
      else toast('An error occurred while loading the Social Media data.')
    })
  }, [])

  const updateSocialMedia = (id: string) => {
    navigate(`/social-media/update/${id}`)
  }

  const detailSocialMedia = (id: string) => {
    navigate(`/social-media/${id}`)
  }

  const deleteSocialMedia = (id: string) => {
    socialMediaServices.remove(id).finally(() => {
      window.location.reload()
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdWork} pageName='Social Media' secondButton='Add Social Media' secondButtonLink='add' />
      <GeneralCard title='Social Media List' customColumn={{ base: 1, md: 1, lg: 1, '2xl': 1 }}>
        <TableContainer>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'NAME'} />
                <Th children={'code'} />
                <Th children={'logo'} />
                <Th children={'orderNumber'} />
                <Th children={'ACTION'} />
              </Tr>
            </Thead>
            {socialMediaData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td onClick={() => detailSocialMedia(item.id)} cursor={'pointer'} children={item.name} />
                    <Td children={item.code} />
                    <Td children={item.logo} />
                    <Td children={item.orderNumber} />
                    <Td
                      children={
                        <ActionButton
                          deleteFunction={deleteSocialMedia}
                          id={item.id}
                          updateFunction={updateSocialMedia}
                        />
                      }
                    />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default SocialMedia
