import { GridItem, FormControl, FormErrorMessage } from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'
import OutlineLabel from './OutlineLabel'

interface CustomCheckboxProps {
  customColSpan?: number
  error?: FieldError | undefined
  children?: React.ReactNode
  title?: string | ''
}

const CustomInputForm: React.FC<CustomCheckboxProps> = ({ customColSpan, error, children, title }) => {
  const defaultColSpan = 2
  const colSpan = customColSpan || defaultColSpan
  const defaultTitle = title || ''
  return (
    <>
      <GridItem
        colSpan={colSpan}
        children={
          <FormControl isInvalid={!!error}>
            <OutlineLabel name={defaultTitle} id={defaultTitle.toLowerCase()} />
            {children}
            <FormErrorMessage children={error?.message} />
          </FormControl>
        }
      />
    </>
  )
}

export default CustomInputForm
