import { axiosInstance } from '../axios/index'

export const userServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('users', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`users/${id}`, requestModel)
  },
  updateStatus: (id: string, requestModel: any) => {
    return axiosInstance.put(`users/updateStatus/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('users')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`users/${id}`)
  },
  findOneWithAddress: (id: string) => {
    return axiosInstance.get(`users/findOneWithAddress/${id}`)
  },
  queryGeneralFilter: (requestModel: any) => {
    return axiosInstance.get(
      `users/queryUserById?openerId=${requestModel.openerId}&ownerId=${requestModel.ownerId}&closerId=${requestModel.closerId}&referenceId=${requestModel.referenceId}&userType=${requestModel.userType}&sortDirection=${requestModel.sortDirection}&searchTag=${requestModel.searchTag}&page=${requestModel.page}&limit=${requestModel.limit}&contractStatus=${requestModel.contractStatus}&countryCode=${requestModel.countryCode}`,
    )
  },
  queryGeneralSearch: (searchTag: string) => {
    return axiosInstance.get(`users/queryGeneralSearch?searchTag=${searchTag}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`users/${id}`)
  },
  updateAssigned: (id: string, requestModel: any) => {
    return axiosInstance.put(`users/updateAssigned/${id}`, requestModel)
  },
  statusList: () => {
    return axiosInstance.get('users/statusList')
  },
}
