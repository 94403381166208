import { Box, Button, Flex, Grid, GridItem, Input, Textarea } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useRef, useState } from 'react'
import * as Yup from 'yup'

import { MdWork } from 'react-icons/md'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { contractTemplateServices } from '../../../services/contracts/contractTemplate/index'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'

import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'
import ProjectSelect from '../../../components/selects/ProjectSelect'
import LanguageSelect from '../../../components/selects/LanguageSelect'
import { Editor } from '@tinymce/tinymce-react'
import ContractTemplateTypeSelect from '../../../components/selects/ContractTemplateTypeSelect'

type ContractTemplateFormData = {
  type: string
  name: string
  description: string
  content: string
  languageCode: string
  projectId: string
}

const ContractTemplateCreate = () => {
  const [selectedContractTemplateType, setSelectedContractTemplateType] = useState<any | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<any | null>(null)
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [editorData, setEditorData] = useState<any>()

  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
  })
  const handleEditorChange = (content: string, editor: any) => {
    setEditorData(content)
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ContractTemplateFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: ContractTemplateFormData) => {
    if (!selectedContractTemplateType) {
      toast('Contract Type is required')
      return
    }

    if (!selectedLanguage) {
      toast('Language is required')
      return
    }

    if (!selectedProject) {
      toast('Project is required')
      return
    }

    if (!editorData) {
      toast('Content is required')
      return
    }

    data.type = selectedContractTemplateType
    data.languageCode = selectedLanguage.code
    data.projectId = selectedProject.id
    data.content = editorData

    contractTemplateServices.create(data).finally(() => {
      navigate('/contract-template')
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdWork} pageName='Contract Template' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Add Contract Template'>
          <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(6, 1fr)' gap={2} margin={'20px'}>
            <CustomInputForm
              title='Project'
              children={
                <ProjectSelect selectedProject={selectedProject} setSelectedProject={setSelectedProject} id='' />
              }
            />
            <CustomInputForm
              title='Type'
              children={
                <ContractTemplateTypeSelect
                  selectedContractTemplateType={selectedContractTemplateType}
                  setSelectedContractTemplateType={setSelectedContractTemplateType}
                  type=''
                />
              }
            />
            <CustomInputForm
              title='Language'
              children={
                <LanguageSelect selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} code='' />
              }
            />
            <CustomInputForm
              title='Name'
              error={errors.name}
              children={<Input type='text' id='name' {...register('name')} />}
            />
            <CustomInputForm
              title='From Description'
              error={errors.description}
              children={<Input type='text' id='description' {...register('description')} />}
            />
            <GridItem
              colSpan={6}
              children={
                <Editor
                  apiKey='uhsd8cs3nsvcr8l0t1nwi8ix65n1p3mb21708bl77jr8wh9d'
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    height: 900,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'code',
                    ],

                    toolbar:
                      'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                  }}
                  onEditorChange={handleEditorChange}
                />
              }
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Add'} />
      </Flex>
    </DefaultLayout>
  )
}
export default ContractTemplateCreate
