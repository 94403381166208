import { Button, Input, Flex, Grid, Box } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useState } from 'react'
import * as Yup from 'yup'

import { userAddressServices } from '../../../../services/users/userAddress'

import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import CustomCheckbox from '../../../../components/customFormElements/CustomCheckbox'
import CountrySelect from '../../../../components/selects/CountrySelect'

type UserAddressFormData = {
  userId: string
  country: string
  countryCode: string
  postCode: string
  city: string
  state: string
  detail: string
  isMain: boolean
}

const UserAddressCreate = () => {
  const [selectedCountry, setSelectedCountry] = useState<any | null>(null)
  const [isChecked, setChecked] = useState(false)
  const id = location.pathname.split('/influencer/')[1]

  const validationSchema = Yup.object().shape({
    postCode: Yup.string().required('Post Code is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    detail: Yup.string().required('Detail is required'),
    isMain: Yup.boolean().required('Main is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const handleCheckChange = (newCheckState: boolean) => {
    setChecked(newCheckState)
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserAddressFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: UserAddressFormData) => {
    if (!data.countryCode && !selectedCountry?.alpha2Code) {
      toast('Country is required')
      return
    }

    data.countryCode = data.countryCode ?? selectedCountry?.alpha2Code
    data.country = data.country ?? selectedCountry?.name
    data.isMain = isChecked
    data.userId = id

    userAddressServices.create(data).finally(() => {
      window.location.reload()
    })
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={6} margin={'20px'}>
          <CustomInputForm
            children={
              <CountrySelect
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectCountry=''
              />
            }
          />
          <CustomInputForm
            error={errors.postCode}
            children={<Input type='text' id='postCode' {...register('postCode')} placeholder='Post Code' />}
          />
          <CustomInputForm
            error={errors.city}
            children={<Input type='text' id='city' {...register('city')} placeholder='City' />}
          />
          <CustomInputForm
            error={errors.state}
            children={<Input type='text' id='state' {...register('state')} placeholder='State' />}
          />
          <CustomInputForm
            error={errors.detail}
            children={<Input type='text' id='detail' {...register('detail')} placeholder='Detail' />}
          />
          <CustomInputForm
            children={
              <CustomCheckbox
                defaultChecked={isChecked}
                onCheckChange={handleCheckChange}
                name={'Main'}
                register={register}
                registerName='isMain'
              />
            }
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Save'} />
      </Flex>
    </Box>
  )
}
export default UserAddressCreate
