import { useState, ChangeEvent } from 'react'
import { Checkbox, Box } from '@chakra-ui/react'
import { UseFormRegister } from 'react-hook-form'

interface CustomCheckboxProps {
  defaultChecked: boolean
  onCheckChange: (newCheckState: boolean) => void
  name: string
  register: UseFormRegister<any>
  registerName: string
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  defaultChecked,
  onCheckChange,
  name,
  register,
  registerName,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked)

  const handleCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked)
    onCheckChange(e.target.checked)
  }

  return (
    <Box display='flex' alignItems='center' width='full' height='40px'>
      <Checkbox
        size='lg'
        isChecked={isChecked}
        id={registerName}
        {...register(registerName)}
        onChange={handleCheckChange}
        sx={{ '& svg': { width: '1.5em', height: '1.5em' } }}
        children={name}
      />
    </Box>
  )
}

export default CustomCheckbox
