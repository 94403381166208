import { Box, Grid } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

// Services
import { userAddressServices } from '../../../../services/users/userAddress'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import DetailCard from '../../../../components/cards/detailCard/DetailCard'

const UserAddressDetail = (props: { id: string }) => {
  const [userAddressData, setUserAddressData] = useState<any | null>(null)

  useEffect(() => {
    userAddressServices.findOne(props.id).then((response) => {
      setUserAddressData(response.data)
    })
  }, [])

  if (!userAddressData) {
    return <LoadingComponent />
  }

  return (
    <Box>
      <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={6} margin={'20px'}>
        <CustomInputForm children={<DetailCard upperText='Language' lowerText={'en'} />} />
        <CustomInputForm children={<DetailCard upperText='Post Code' lowerText={userAddressData.postCode} />} />
        <CustomInputForm children={<DetailCard upperText='City' lowerText={userAddressData.city} />} />
        <CustomInputForm children={<DetailCard upperText='State' lowerText={userAddressData.state} />} />
        <CustomInputForm children={<DetailCard upperText='Detail' lowerText={userAddressData.detail} />} />
        <CustomInputForm children={<DetailCard upperText='Main' lowerText={userAddressData.isMain.toString()} />} />
      </Grid>
    </Box>
  )
}
export default UserAddressDetail
