import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Stack,
} from '@chakra-ui/react'
import { sharedDetailServices } from '../../services/sharedDetail/index'
import { ChevronDownIcon } from '@chakra-ui/icons'

import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import PaymentStatusBadge from '../../components/customFormElements/PaymentStatusBadge'
import { PaymentType } from '../../enums/payment-type'

const SharedDetail = () => {
  const [sharedDetailData, setSharedDetail] = useState<any[]>([])

  const id = location.pathname.split('/influencer/')[1]

  const updatePaymentStatus = (sharedDetailId: string, status: string) => {
    sharedDetailServices.paymentStatus(sharedDetailId, status).finally(() => {
      setSharedDetailFunction()
    })
  }

  const setSharedDetailFunction = () => {
    sharedDetailServices.findByUserId(id).then((response: any) => {
      if (response) {
        setSharedDetail(response.data)
      } else {
        toast('An error occurred while loading the User Email data.')
      }
    })
  }

  useEffect(() => {
    sharedDetailServices.findByUserId(id).then((response: any) => {
      if (response) {
        setSharedDetail(response.data)
      } else {
        toast('An error occurred while loading the User Email data.')
      }
    })
  }, [])

  return (
    <>
      <TableContainer maxH='20em' overflowY='auto'>
        <Table variant='simple' size={'sm'}>
          <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
            <Tr>
              <Th>Shared Type</Th>
              <Th>Which Quarter</Th>
              <Th>Total Quarter</Th>
              <Th>It will be paid</Th>
              <Th>Registration Code</Th>
              <Th>It was paid</Th>
              <Th>Created Date</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          {sharedDetailData.map((item) => {
            return (
              <Tbody paddingBottom={'20px'} key={item.id}>
                <Tr>
                  <Td>{item.sharedType}</Td>
                  <Td>{item.whichQuarter}</Td>
                  <Td>{item.totalQuarter}</Td>
                  <Td>{item.paid}</Td>
                  <Td>{item.registrationCode}</Td>
                  <Td>
                    <Stack direction='row'>
                      <PaymentStatusBadge status={item.paymentStatus} />
                    </Stack>
                  </Td>
                  <Td>{item.createdDate.slice(0, 10)}</Td>
                  <Td>
                    {item.paymentStatus == PaymentType.Waiting && (
                      <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                          Actions
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            onClick={() => updatePaymentStatus(item.id, PaymentType.Paid)}
                            cursor={'pointer'}
                            color={'green'}
                          >
                            Pay
                          </MenuItem>

                          <MenuItem
                            onClick={() => updatePaymentStatus(item.id, PaymentType.Cancelled)}
                            cursor={'pointer'}
                            color={'red'}
                          >
                            Cancel
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    )}
                  </Td>
                </Tr>
              </Tbody>
            )
          })}

          <Tfoot paddingTop={'20px'}>
            <Tr></Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </>
  )
}

export default SharedDetail
