import { ReactNode } from 'react'
import {
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  Image,
} from '@chakra-ui/react'
import { FiHome } from 'react-icons/fi'
import { FaLanguage, FaCalendarAlt, FaImage } from 'react-icons/fa'
import { MdWork, MdAccountBox, MdOutlineManageAccounts, MdSocialDistance } from 'react-icons/md'
import { HiTemplate } from 'react-icons/hi'
import { CgTemplate } from 'react-icons/cg'
import { AiOutlineTags, AiOutlineLink } from 'react-icons/ai'

import { GiLevelEndFlag } from 'react-icons/gi'
import { IconType } from 'react-icons'
import SearchBar from './SearchBar'
import SideBarItem from './SideBarItem'

interface LinkItemProps {
  name: string
  icon: IconType
  to: string
  p?: number
  mx?: number
  fontSize?: number
  iconSize?: number
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Dashboard', icon: FiHome, to: '/' },
  { name: 'Influencers', icon: MdAccountBox, to: '/influencer' },
  { name: 'Manage Team', icon: MdOutlineManageAccounts, to: '/manage-employee' },
  { name: 'Calendar', icon: FaCalendarAlt, to: '/calendar' },
  { name: 'Mail Template', icon: HiTemplate, to: '/mail-template' },
  { name: 'Contract Level', icon: GiLevelEndFlag, to: '/contract-level' },
  { name: 'Contract Template', icon: CgTemplate, to: '/contract-template' },
  { name: 'Content Bank', icon: FaImage, to: '/content-bank' },
  { name: 'Tags', icon: AiOutlineTags, to: '/content-bank/tag', p: 1, mx: 10, fontSize: 14, iconSize: 20 },

  { name: 'Project', icon: MdWork, to: '/project' },
  { name: 'Language', icon: FaLanguage, to: '/language' },
  { name: 'Social Media', icon: MdSocialDistance, to: '/social-media' },
  // { name: 'Shared Detail', icon: MdFolderShared, to: '/shared-detail' },
  // { name: 'Video Link', icon: MdMovie, to: '/video-link' },
  // { name: 'Country', icon: GiWorld, to: '/country' },
]

export default function DefaultLayout({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box minH='100vh' bg={useColorModeValue('white.100', 'white.900')}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent children={<SidebarContent onClose={onClose} />} />
      </Drawer>

      <SearchBar onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p='0' children={children} />
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (
    <Box
      transition='3s ease'
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      {...rest}
    >
      <Flex h='20' alignItems='center' mx='8' justifyContent='space-between' marginBottom={'15px'}>
        <Text fontSize='5xl' fontFamily='monospace' fontWeight='bold' color='red'>
          <Image src='https://i.ibb.co/gSDJKH1/Social-removebg-preview.png' alt='SociAI CRM' />
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <SideBarItem
          key={link.name}
          icon={link.icon}
          to={link.to}
          children={link.name}
          p={link.p}
          mx={link.mx}
          fontSize={link.fontSize}
          iconSize={link.iconSize}
        />
      ))}
    </Box>
  )
}
