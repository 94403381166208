import { axiosInstance } from '../../axios'

export const userSocialServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('user-socials', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`user-socials/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('user-socials')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`user-socials/${id}`)
  },
  findByUserId: (id: string) => {
    return axiosInstance.get(`user-socials/findByUserId/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`user-socials/${id}`)
  },
}
