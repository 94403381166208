import { Button, Flex, Grid } from '@chakra-ui/react'
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import { toast } from 'react-toastify'
import GenericSelect from '../../../components/selects/GenericSelect'
import { tagServices } from '../../../services/tags'
import { useEffect, useState } from 'react'
import { contentTagServices } from '../../../services/contentTag'
import { languageServices } from '../../../services/languages'

interface tagDatas {
  id: string
  tag: string
}
interface languageData {
  name: string
  code: string
}
interface contentTagData {
  contentId: string
  tagId: string
  tag: string
}

const ContentBankCreate: React.FC = () => {
  const [tagData, setTagData] = useState<tagDatas[]>([])
  const [languageData, setLanguageData] = useState<languageData[]>([])
  const [selectedlanguageData, setSelectedLanguageData] = useState<languageData | null>(null)
  const [selectedTag, setSelectedTag] = useState<tagDatas | null>(null)
  const id = location.pathname.split('/content-bank/')[1]

  useEffect(() => {
    languageServices.findAll().then((response) => {
      setLanguageData(response.data)
    })
    if (selectedlanguageData) {
      tagServices.findAll(selectedlanguageData.code, 1, 10).then((response) => {
        setTagData(response.data.items)
      })
    }
  }, [selectedlanguageData])

  const onSubmit = async () => {
    if (!selectedTag) {
      toast('Tag is required')
      return
    }
    const data = {
      contentId: id,
      tagId: selectedTag.id,
      tag: selectedTag.tag,
    } as contentTagData

    contentTagServices.create(data).then((response) => {
      if (response) window.location.reload()
    })
  }

  return (
    <>
      <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
        <CustomInputForm
          title='Language'
          children={
            <GenericSelect
              selectedValue={selectedlanguageData}
              setSelectedValue={setSelectedLanguageData}
              data={languageData}
              getOptionLabel={(language: any) => language.name}
              getOptionValue={(language: any) => language.code}
              placeholder='Select a Language'
            />
          }
        />
        <CustomInputForm
          title='Tag'
          children={
            <GenericSelect
              selectedValue={selectedTag}
              setSelectedValue={setSelectedTag}
              data={tagData}
              getOptionLabel={(tag: any) => tag.tag}
              getOptionValue={(tag: any) => tag.id}
              placeholder='Select a Tag'
            />
          }
        />
      </Grid>

      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={onSubmit}>
          Save
        </Button>
      </Flex>
    </>
  )
}

export default ContentBankCreate
