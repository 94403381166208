import { Box, Text, Grid, GridItem, Heading } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

// Services
import { userEmailServices } from '../../../../services/users/userEmail/index'

// Components
import LoadingComponent from '../../../../components/shareds/LoadingComponent'

const UserEmailDetail = (props: { id: string }) => {
  const [userEmailData, setUserEmailData] = useState<any | null>(null)

  useEffect(() => {
    userEmailServices.findOne(props.id).then((response) => {
      setUserEmailData(response.data)
    })
  }, [])

  if (!userEmailData) {
    return <LoadingComponent />
  }

  return (
    <Box>
      <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
        <GridItem
          colSpan={2}
          children={
            <>
              <Heading children='Email' size={'sm'} as='h4' marginBottom={'5px'} />
              <Text children={userEmailData.email} />
            </>
          }
        />
      </Grid>
    </Box>
  )
}
export default UserEmailDetail
//
