import { Box, Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { userAddressServices } from '../../../../services/users/userAddress/index'

import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import CustomCheckbox from '../../../../components/customFormElements/CustomCheckbox'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import CountrySelect from '../../../../components/selects/CountrySelect'

type UserAddressFormData = {
  userId: string
  country: string
  countryCode: string
  postCode: string
  city: string
  state: string
  detail: string
  isMain: boolean
}

const UserAddressUpdate = (props: { id: string; closeModal: () => void }) => {
  const [selectedCountry, setSelectedCountry] = useState<any | null>(null)
  const [userAddressData, setUserAddressData] = useState<any | null>(null)
  const [isChecked, setChecked] = useState(false)

  const validationSchema = Yup.object().shape({
    postCode: Yup.string().required('Post Code is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    detail: Yup.string().required('Detail is required'),
    isMain: Yup.boolean().required('Main is required'),
  })

  useEffect(() => {
    userAddressServices.findOne(props.id).then((response) => {
      if (response) {
        setUserAddressData(response.data)
        setChecked(response.data.isMain)
      }
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserAddressData({
      ...userAddressData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }
  const handleCheckChange = (newCheckState: boolean) => {
    setChecked(newCheckState)
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserAddressFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: UserAddressFormData) => {
    if (!data.countryCode) {
      data.countryCode = selectedCountry.alpha2Code
    }
    if (!data.isMain) {
      data.isMain = isChecked
    }
    if (!data.country) {
      data.country = selectedCountry.name
    }

    userAddressServices.update(props.id, data).finally(() => {
      props.closeModal()
    })
  }

  if (!userAddressData) {
    return <LoadingComponent />
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            error={errors.countryCode}
            title='Country'
            children={
              <CountrySelect
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectCountry={userAddressData.countryCode}
              />
            }
          />
          <CustomInputForm
            error={errors.postCode}
            title='Post Code'
            children={
              <Input
                type='text'
                id='postCode'
                {...register('postCode')}
                placeholder='Post Code'
                value={userAddressData.postCode}
                onChange={handleInputChange}
              />
            }
          />
          <CustomInputForm
            error={errors.city}
            title='City'
            children={
              <Input
                type='text'
                id='city'
                {...register('city')}
                placeholder='City'
                value={userAddressData.city}
                onChange={handleInputChange}
              />
            }
          />
          <CustomInputForm
            error={errors.state}
            title='State'
            children={
              <Input
                type='text'
                id='state'
                {...register('state')}
                placeholder='State'
                value={userAddressData.state}
                onChange={handleInputChange}
              />
            }
          />
          <CustomInputForm
            error={errors.postCode}
            title='Detail'
            children={
              <Input
                type='text'
                id='detail'
                {...register('detail')}
                placeholder='Detail'
                value={userAddressData.detail}
                onChange={handleInputChange}
              />
            }
          />
          <Box
            mt={5}
            children={
              <CustomInputForm
                error={errors.postCode}
                children={
                  <CustomCheckbox
                    defaultChecked={isChecked}
                    onCheckChange={handleCheckChange}
                    name={'Main'}
                    register={register}
                    registerName='isMain'
                  />
                }
              />
            }
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </Box>
  )
}
export default UserAddressUpdate
