import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MdAccountBox } from 'react-icons/md'
import { useEffect, useState } from 'react'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { userServices } from '../../../services/users'

// Components
import GeneralFilter from '../../../components/generalFilter/AlternativeGeneralFilter'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'
// import UserTable from '../../../components/tables/UserTable'
import UserTable from '../../../components/tables/NewTable'

const User = () => {
  const [userData, setUsersData] = useState<any[]>([])
  const [inputValue, setInputValue] = useState('')
  const [contractStatus, setContractStatus] = useState<any | null>(null)
  const [opener, setOpener] = useState<any | null>(null)
  const [owner, setOwner] = useState<any | null>(null)
  const [closer, setCloser] = useState<any | null>(null)
  const [userType, setUserType] = useState<any | null>(null)
  const [country, setCountry] = useState<any | null>(null)
  const [user, setUser] = useState<any | null>(null)
  const [sortByValue, setSortBy] = useState<any | null>(null)
  const [pageNumber, setPageNubmer] = useState('')
  const [pageLimit, setPageLimit] = useState('')
  const [totalItems, setTotalItems] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(0)

  useEffect(() => {
    const requestModel = {
      openerId: opener?.id || '',
      ownerId: owner?.id || '',
      closerId: closer?.id || '',
      referenceId: user?.id || '',
      userType: userType || '',
      sortDirection: sortByValue?.value || '',
      searchTag: inputValue.length >= 3 ? inputValue : '',
      page: pageNumber || 1,
      limit: pageLimit || 10,
      contractStatus: contractStatus || '',
      countryCode: country?.alpha2Code || '',
    }

    if (
      opener ||
      owner ||
      closer ||
      inputValue.length >= 3 ||
      userType ||
      country ||
      user ||
      sortByValue ||
      pageNumber ||
      pageLimit
    ) {
      userServices.queryGeneralFilter(requestModel).then((response) => {
        if (response) {
          setUsersData(response.data.items)
          setTotalPages(response.data.meta.totalPages)
          setCurrentPage(response.data.meta.currentPage)
          setTotalItems(response.data.meta.totalItems)
        }
      })
    }
  }, [contractStatus, opener, owner, closer, inputValue, userType, country, user, sortByValue, pageNumber, pageLimit])

  return (
    <DefaultLayout>
      <NavCard
        icon={MdAccountBox}
        pageName='Influencer'
        firstButton='Invite'
        firstButtonLink='invite-influencer'
        secondButton='Add Influencer'
        secondButtonLink='add'
        key={'Nav_Card'}
      />
      <GeneralCard title=''>
        <GeneralFilter
          setSearchInput={setInputValue}
          setSelectedContractStatus={setContractStatus}
          setSelectedCloser={setCloser}
          setSelectedOpener={setOpener}
          setSelectedOwner={setOwner}
          setSelectedUserType={setUserType}
          setSelectedCountry={setCountry}
          setSelectedUser={setUser}
          setSelectedSortBy={setSortBy}
        />
      </GeneralCard>

      <GeneralCard title='Influencer List'>
        <UserTable
          setSelectedPageLimit={setPageLimit}
          setSelectedPageNumber={setPageNubmer}
          userData={userData}
          totalItems={totalItems}
          totalPages={totalPages}
          currentPage={currentPage}
        />
        <ToastContainer />
      </GeneralCard>
    </DefaultLayout>
  )
}

export default User
