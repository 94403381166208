import React from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'

interface CustomAlertDialogProps {
  confirmAction: (id: string) => void
  onOpenDialog: () => void
  id: string
}

interface AlertDialogComponentProps {
  isOpen: boolean
  onClose: () => void
  cancelRef: React.RefObject<HTMLButtonElement>
  onConfirm: () => void
}

const AlertDialogComponent: React.FC<AlertDialogComponentProps> = ({ isOpen, onClose, cancelRef, onConfirm }) => (
  <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader fontSize='lg' fontWeight='bold' children={'Delete Item'} />
        <AlertDialogBody children={`Are you sure? You can't undo this action afterwards.`} />
        <AlertDialogFooter>
          <Button colorScheme='red' onClick={onConfirm} children={'Delete'} />
          <Button ref={cancelRef} onClick={onClose} ml={3} children={'Cancel'} />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>
)

export default AlertDialogComponent
