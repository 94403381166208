import { Grid, GridItem, Input } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

// Components
import ContractStatusSelect from '../selects/ContractStatusSelect'
import AssignedSelect from '../selects/AssignedSelect'
import UserTypeSelect from '../selects/UserTypeSelect'
import CountrySelect from '../selects/CountrySelect'
import SortBySelect from '../selects/SortBySelect'
import UserSelect from '../selects/UserSelect'

interface ArrayObjectSelectState {
  setSearchInput: (searchTag: any) => void
  setSelectedContractStatus: (status: any) => void
  setSelectedOpener: (opener: any) => void
  setSelectedOwner: (owner: any) => void
  setSelectedCloser: (closer: any) => void
  setSelectedUserType: (userType: any) => void
  setSelectedCountry: (country: any) => void
  setSelectedUser: (country: any) => void
  setSelectedSortBy: (sortByValue: any) => void
}

const GeneralFilter: React.FC<ArrayObjectSelectState> = ({
  setSearchInput,
  setSelectedContractStatus,
  setSelectedOpener,
  setSelectedOwner,
  setSelectedCloser,
  setSelectedUserType,
  setSelectedCountry,
  setSelectedUser,
  setSelectedSortBy,
}) => {
  const [contractStatus, setContractStatus] = useState<any | null>(null)

  const [inputValue, setInputValue] = useState('')
  const [opener, setOpener] = useState<any | null>(null)
  const [owner, setOwner] = useState<any | null>(null)
  const [closer, setCloser] = useState<any | null>(null)
  const [userType, setUserType] = useState<any | null>(null)
  const [country, setCountry] = useState<any | null>(null)
  const [user, setUser] = useState<any | null>(null)
  const [sortByValue, setSortBy] = useState<any | null>(null)

  useEffect(() => {
    setSearchInput(inputValue)
    setSelectedContractStatus(contractStatus)
    setSelectedOpener(opener)
    setSelectedOwner(owner)
    setSelectedCloser(closer)
    setSelectedUserType(userType)
    setSelectedCountry(country)
    setSelectedUser(user)
    setSelectedSortBy(sortByValue)
  }, [opener, owner, closer, contractStatus, inputValue, userType, country, user, sortByValue])

  return (
    <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(5, 1fr)' gap={2}>
      <GridItem
        colSpan={2}
        children={
          <Input bg={'white'} type='text' placeholder='Search...' onChange={(e) => setInputValue(e.target.value)} />
        }
      />
      <GridItem
        colSpan={1}
        children={<AssignedSelect assignedType='opener' selectedAssigned={opener} setSelectedAssigned={setOpener} />}
      />
      <GridItem
        colSpan={1}
        children={<AssignedSelect assignedType='closer' selectedAssigned={closer} setSelectedAssigned={setCloser} />}
      />
      <GridItem
        colSpan={1}
        children={<AssignedSelect assignedType='owner' selectedAssigned={owner} setSelectedAssigned={setOwner} />}
      />

      <GridItem
        colSpan={1}
        children={
          <ContractStatusSelect selectedContractStatus={contractStatus} setSelectedContractStatus={setContractStatus} />
        }
      />
      <GridItem
        colSpan={1}
        children={<UserTypeSelect selectedUserType={userType} setSelectedUserType={setUserType} />}
      />
      <GridItem
        colSpan={1}
        children={<CountrySelect selectCountry={''} setSelectedCountry={setCountry} selectedCountry={country} />}
      />
      <GridItem
        colSpan={1}
        children={<UserSelect selectedUser={user} setSelectedUser={setUser} placeHolderText='REFERRED' id='' />}
      />
      <GridItem
        colSpan={1}
        children={<SortBySelect selectedSortBy={sortByValue} setSelectedSortBy={setSortBy} value='DESC' />}
      />
    </Grid>
  )
}

export default GeneralFilter
