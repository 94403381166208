import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import UserContractReview from './review'
import { toast } from 'react-toastify'

// Services
import { userContractServices } from '../../../services/users/userContract/index'

// Components
import ActionButton from '../../../components/customFormElements/ActionButtons'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import CustomModal from '../../../components/modal/CustomModal'

const UserContract = () => {
  const [userContractData, setUserContractsData] = useState<any[]>([])
  const [userContractId, setUserContractId] = useState<string>()

  const { isOpen: isOpenDetail, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure()

  const id = location.pathname.split('/influencer/')[1]

  useEffect(() => {
    userContractServices.findByUserId(id).then((response) => {
      if (response) {
        setUserContractsData(response.data)
      } else {
        toast('An error occurred while loading the User Contract data.')
      }
    })
  }, [id])

  const detailFunction = (contractId: string) => {
    setUserContractId(contractId)
    onOpenDetail()
  }

  const setUserContractFunction = () => {
    userContractServices.findByUserId(id).then((response) => {
      if (response) {
        setUserContractsData(response.data)
      } else {
        toast('An error occurred while loading the User Contract data.')
      }
    })
  }

  const deleteUserContract = (id: string) => {
    userContractServices.remove(id).finally(() => {
      setUserContractFunction()
    })
  }

  if (!userContractData) {
    return <LoadingComponent />
  }

  return (
    <>
      <TableContainer maxH='20em' overflowY='auto'>
        <Table variant='simple' size={'sm'}>
          <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
            <Tr>
              <Th children={'Template Name'} />
              <Th children={'Contract Status'} />
              <Th children={'Value'} />
              <Th children={'Company'} />
              <Th children={'Sign Date'} />
              <Th children={'End Date'} />
              <Th children={'Action'} />
            </Tr>
          </Thead>
          {userContractData.map((item) => {
            return (
              <Tbody paddingBottom={'20px'} key={item.id}>
                <Tr>
                  <Td
                    onClick={() => detailFunction(item.id)}
                    cursor={'pointer'}
                    color={'blue'}
                    children={<Link children={item.userName} />}
                  />
                  <Td children={item.status.toUpperCase()} />
                  <Td children={item.contractShares ? item.contractShares : ''} />
                  <Td children={item.projectName} />
                  <Td children={item.signedDate ? item.signedDate.slice(0, 10) : ''} />
                  <Td children={item.expirationDate ? item.expirationDate.slice(0, 10) : ''} />
                  <Td children={<ActionButton id={item.id} deleteFunction={deleteUserContract} />} />
                </Tr>
              </Tbody>
            )
          })}
        </Table>
      </TableContainer>

      {
        <CustomModal
          title='User Contract Detail'
          isOpen={isOpenDetail}
          onClose={onCloseDetail}
          key={'User_Contract_Detail'}
          size='6xl'
          children={<UserContractReview id={userContractId ? userContractId : ''} />}
        />
      }
    </>
  )
}

export default UserContract
