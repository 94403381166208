import { GridItem, Text, Card, CardBody, Heading } from '@chakra-ui/react'

export default function DetailCard(props: { upperText: string; lowerText: string }) {
  return (
    <>
      <GridItem
        colSpan={2}
        children={
          <Card>
            <CardBody>
              <Heading size='xs' textTransform='uppercase' children={props.upperText} />
              <Text pt='2' fontSize='sm' children={props.lowerText} />
            </CardBody>
          </Card>
        }
      />
    </>
  )
}
