import { axiosInstance } from '../axios'

export const sharedDetailServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('shared-details', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`shared-details/${id}`, requestModel)
  },
  paymentStatus: (id: string, status: string) => {
    return axiosInstance.put(`shared-details/paymentStatus/${id}/${status}`)
  },
  findAll: () => {
    return axiosInstance.get('shared-details')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`shared-details/${id}`)
  },
  findByUserId: (id: string) => {
    return axiosInstance.get(`shared-details/findByUserId/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`shared-details/${id}`)
  },
}
