import { axiosInstance } from '../axios'

export const calendarServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('calendars', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`calendars/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('calendars')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`calendars/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`calendars/${id}`)
  },
  findByUserId: (id: string) => {
    return axiosInstance.get(`calendars/find-by-userId/${id}`)
  },
  confirmCalendar: (id: string) => {
    return axiosInstance.get(`calendars/confirmCalendar/${id}`)
  },
  queryGeneralSearch: (id: string) => {
    return axiosInstance.get(`calendars/queryGeneralSearch/${id}`)
  },
  claendarStatusList: () => {
    return axiosInstance.get(`calendars/claendarStatusList/`)
  },
}
