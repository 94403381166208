import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { GiLevelEndFlag } from 'react-icons/gi'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { contractLevelServices } from '../../../services/contractLevels/index'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'
import LanguageSelect from '../../../components/selects/LanguageSelect'
import ProjectSelect from '../../../components/selects/ProjectSelect'
import { toast } from 'react-toastify'

type ContractLevelFormData = {
  name: string
  description: string
  level: number
  firstValue: number
  secondValue: number
  thirdValue: number
  firstTitle: string
  secondTitle: string
  thirdTitle: string
  currencyType: string
  languageCode: string
  projectId: string
}

const CompanyUpdate = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<any | null>(null)
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [contractLevelData, setContractLevelData] = useState<any | null>(null)
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    level: Yup.number().required('Level is required'),
    firstValue: Yup.number().required('First Value is required'),
    secondValue: Yup.number().required('Second Value is required'),
    thirdValue: Yup.number().required('Third Value is required'),
    firstTitle: Yup.string().required('First Title is required'),
    secondTitle: Yup.string().required('Second Title is required'),
    thirdTitle: Yup.string().required('Third Title is required'),
    currencyType: Yup.string().required('Currency Type is required'),
  })

  const id = location.pathname.split('/contract-level/update/')[1]

  useEffect(() => {
    contractLevelServices.findOne(id).then((response) => {
      if (response) setContractLevelData(response.data)
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContractLevelData({ ...contractLevelData, [event.target.name]: event.target.value })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ContractLevelFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: ContractLevelFormData) => {
    if (!data.projectId && !selectedProject?.id) {
      toast('Project is required')
      return
    }

    if (!data.languageCode && !selectedLanguage?.code) {
      toast('Language is required')
      return
    }

    data.projectId = data.projectId ?? selectedProject?.id
    data.languageCode = data.languageCode ?? selectedLanguage?.code

    contractLevelServices.update(id, data).finally(() => {
      navigate('/contract-level')
    })
  }

  if (!contractLevelData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={GiLevelEndFlag} pageName='Contract Level' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Update Contract Level'>
          <Grid templateRows='repeat(4, 1fr)' templateColumns='repeat(6, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              error={errors.name}
              children={
                <Input
                  type='text'
                  id='name'
                  {...register('name')}
                  value={contractLevelData.name}
                  onChange={handleInputChange}
                />
              }
              title='Name'
            />
            <CustomInputForm
              error={errors.description}
              children={
                <Input
                  type='text'
                  id='description'
                  {...register('description')}
                  value={contractLevelData.description}
                  onChange={handleInputChange}
                />
              }
              title='Description'
            />
            <CustomInputForm
              error={errors.firstValue}
              children={
                <Input
                  type='text'
                  id='firstValue'
                  {...register('firstValue')}
                  value={contractLevelData.firstValue}
                  onChange={handleInputChange}
                />
              }
              title='First Value'
            />
            <CustomInputForm
              error={errors.secondValue}
              children={
                <Input
                  type='text'
                  id='secondValue'
                  {...register('secondValue')}
                  value={contractLevelData.secondValue}
                  onChange={handleInputChange}
                />
              }
              title='Second Value'
            />
            <CustomInputForm
              error={errors.thirdValue}
              children={
                <Input
                  type='text'
                  id='thirdValue'
                  {...register('thirdValue')}
                  value={contractLevelData.thirdValue}
                  onChange={handleInputChange}
                />
              }
              title='Third Value'
            />
            <CustomInputForm
              error={errors.firstTitle}
              children={
                <Input
                  type='text'
                  id='logoUrl'
                  {...register('firstTitle')}
                  value={contractLevelData.firstTitle}
                  onChange={handleInputChange}
                />
              }
              title='First Title'
            />
            <CustomInputForm
              error={errors.secondTitle}
              children={
                <Input
                  type='text'
                  id='logoUrl'
                  {...register('secondTitle')}
                  value={contractLevelData.secondTitle}
                  onChange={handleInputChange}
                />
              }
              title='Second Title'
            />
            <CustomInputForm
              error={errors.thirdTitle}
              children={
                <Input
                  type='text'
                  id='thirdTitle'
                  {...register('thirdTitle')}
                  value={contractLevelData.thirdTitle}
                  onChange={handleInputChange}
                />
              }
              title='Third Title'
            />
            <CustomInputForm
              error={errors.languageCode}
              children={
                <LanguageSelect
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  code={contractLevelData.languageCode}
                />
              }
              title='Language'
            />
            <CustomInputForm
              error={errors.projectId}
              children={
                <ProjectSelect
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  id={contractLevelData.projectId}
                />
              }
              title='Project'
            />
            <CustomInputForm
              customColSpan={1}
              error={errors.level}
              children={
                <Input
                  type='number'
                  id='level'
                  {...register('level')}
                  value={contractLevelData.level}
                  onChange={handleInputChange}
                />
              }
              title='Level'
            />
            <CustomInputForm
              customColSpan={1}
              error={errors.currencyType}
              children={
                <Input
                  type='text'
                  id='currencyType'
                  {...register('currencyType')}
                  value={contractLevelData.currencyType}
                  onChange={handleInputChange}
                />
              }
              title='Currency Type'
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </DefaultLayout>
  )
}
export default CompanyUpdate
