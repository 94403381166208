import { axiosInstance } from '../axios/index'

export const employeeServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('employees', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`employees/${id}`, requestModel)
  },
  findAll: (firstName: string, lastName: string, page: number, limit: number) => {
    return axiosInstance.get(`employees?firstName=${firstName}&lastName=${lastName}&page=${page}&limit=${limit}`)
  },
  findOne: (id: string) => {
    return axiosInstance.get(`employees/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`employees/${id}`)
  },

  findEmployeesByType: (type: string) => {
    const result = axiosInstance.get(`employees/employeeByType/${type}`)
    return result
  },
}
