import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { MdWork } from 'react-icons/md'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { socialMediaServices } from '../../../services/socialMedias/index'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'

type SocialMediaFormData = {
  name: string
  code: string
  logo: string
  orderNumber: number
  minFollowerCountForLevelOne: number
  maxFollowerCountForLevelOne: number
  minFollowerCountForLevelTwo: number
  maxFollowerCountForLevelTwo: number
  minFollowerCountForLevelThree: number
  minEngagementRate: number
}

const SocialMediaCreate = () => {
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.string().required('Title is required'),
    logo: Yup.string().required('Description is required'),
    orderNumber: Yup.number().required('Number is required'),
    minFollowerCountForLevelOne: Yup.number().required('Address is required'),
    maxFollowerCountForLevelOne: Yup.number().required('Person is required'),
    minFollowerCountForLevelTwo: Yup.number().required('E-mail is required'),
    maxFollowerCountForLevelTwo: Yup.number().required('Logo Url is required'),
    minFollowerCountForLevelThree: Yup.number().required('Termsheet is required'),
    minEngagementRate: Yup.number().required('Default is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SocialMediaFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: SocialMediaFormData) => {
    socialMediaServices.create(data).finally(() => {
      navigate('/social-media')
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdWork} pageName='Social Media' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Add Social Media'>
          <Grid templateRows='repeat(6, 1fr)' templateColumns='repeat(4, 1fr)' gap={6} margin={'20px'}>
            <CustomInputForm
              title='Name'
              error={errors.name}
              children={<Input type='text' id='name' {...register('name')} />}
            />
            <CustomInputForm
              title='Code'
              error={errors.code}
              children={<Input type='text' id='code' {...register('code')} />}
            />
            <CustomInputForm
              title='Logo URL'
              error={errors.logo}
              children={<Input type='text' id='logo' {...register('logo')} />}
            />
            <CustomInputForm
              title='Order Number'
              error={errors.orderNumber}
              children={<Input type='text' id='orderNumber' {...register('orderNumber')} />}
            />
            <CustomInputForm
              title='Min Follower Count For Level One'
              error={errors.minFollowerCountForLevelOne}
              children={
                <Input type='text' id='minFollowerCountForLevelOne' {...register('minFollowerCountForLevelOne')} />
              }
            />
            <CustomInputForm
              title='Max Follower Count For Level One'
              error={errors.maxFollowerCountForLevelOne}
              children={
                <Input type='text' id='maxFollowerCountForLevelOne' {...register('maxFollowerCountForLevelOne')} />
              }
            />
            <CustomInputForm
              title='Min Follower Count For Level Two'
              error={errors.minFollowerCountForLevelTwo}
              children={
                <Input type='text' id='minFollowerCountForLevelTwo' {...register('minFollowerCountForLevelTwo')} />
              }
            />
            <CustomInputForm
              title='Max Follower Count For Level Two'
              error={errors.maxFollowerCountForLevelTwo}
              children={
                <Input type='text' id='maxFollowerCountForLevelTwo' {...register('maxFollowerCountForLevelTwo')} />
              }
            />
            <CustomInputForm
              title='Min Follower Count For Level Three'
              error={errors.minFollowerCountForLevelThree}
              children={
                <Input type='text' id='minFollowerCountForLevelThree' {...register('minFollowerCountForLevelThree')} />
              }
            />
            <CustomInputForm
              title='Min Engagement Rate'
              error={errors.minEngagementRate}
              children={<Input type='text' id='minEngagementRate' {...register('minEngagementRate')} />}
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Add'} />
      </Flex>
    </DefaultLayout>
  )
}
export default SocialMediaCreate
