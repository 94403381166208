import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Grid } from '@chakra-ui/react'

import DefaultLayout from '../../../layouts/DefaultLayout'

import { MdQuestionAnswer } from 'react-icons/md'

// Services
import { projectServices } from '../../../services/projects'

// Components
import UpdateDeleteButton from '../../../components/customFormElements/UpdateDeleteButton'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import DetailCard from '../../../components/cards/detailCard/DetailCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'

const ProjectDetail = () => {
  const [projectData, setProjectData] = useState<any | null>(null)

  const id = location.pathname.split('/project/')[1]
  const navigate = useNavigate()

  useEffect(() => {
    projectServices.findOne(id).then((response: any) => {
      setProjectData(response.data)
    })
  }, [])

  const deleteFunction = (id: string) => {
    projectServices.remove(id).finally(() => {
      navigate('/project')
    })
  }

  if (!projectData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdQuestionAnswer} pageName='Project' />

      <GeneralCard title='Project Detail'>
        <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <DetailCard upperText='Name' lowerText={projectData.name} />
          <DetailCard upperText='Title' lowerText={projectData.title} />
          <DetailCard upperText='Description' lowerText={projectData.description} />
          <DetailCard upperText='Number' lowerText={projectData.number} />
          <DetailCard upperText='Address' lowerText={projectData.address} />
          <DetailCard upperText='Person' lowerText={projectData.person} />
          <DetailCard upperText='Email' lowerText={projectData.email} />
          <DetailCard upperText='LogoUrl' lowerText={projectData.logoUrl} />
          <DetailCard upperText='Termsheet' lowerText={projectData.isTermsheet.toString()} />
          <DetailCard upperText='Default' lowerText={projectData.isDefault.toString()} />
          <DetailCard upperText='Established Date' lowerText={projectData.establishedDate.slice(0, 10)} />
        </Grid>
      </GeneralCard>

      <UpdateDeleteButton deleteFunction={deleteFunction} id={id} navigateUrl='/project/update' />
    </DefaultLayout>
  )
}

export default ProjectDetail
