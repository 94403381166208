import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { employeeServices } from '../../services/employees/index'
import { userServices } from '../../services/users'
interface ResponseData {
  id: string
  firstName: string
  lastName: string
  type: string
}

interface AssignedData {
  id: string
  name: string
  type: string
}

interface ArrayObjectSelectState {
  assignedType: string
  userId: string
  id?: string
}

const AssignedSelectAndUpdate: React.FC<ArrayObjectSelectState> = ({ assignedType, userId, id }) => {
  const [selectedAssigned, setSelectedAssigned] = useState<AssignedData | null>(null)
  const [assigned, setAssigned] = useState<AssignedData[]>([])

  const updateAssigned = (id: string, option: AssignedData | null) => {
    setSelectedAssigned(option)
    const requestModel = {
      employeeId: option?.id,
      employeeType: option?.type,
    }
    userServices.updateAssigned(id, requestModel)
  }

  useEffect(() => {
    employeeServices.findEmployeesByType(assignedType).then((response) => {
      if (response) {
        const employeeData = response.data as ResponseData[]

        const assignedData = employeeData.map<AssignedData>((item) => {
          return {
            id: item.id,
            name: item.firstName + ' ' + item.lastName,
            type: item.type,
          }
        })
        setAssigned(assignedData)
        const result = assignedData.find((item) => item.id === id)
        if (result) {
          setSelectedAssigned(result)
        }
      } else {
        toast('An error occurred while loading the Assigned data.')
      }
    })
  }, [id])

  return (
    <Select
      value={selectedAssigned}
      onChange={(option: AssignedData | null) => {
        updateAssigned(userId, option)
      }}
      getOptionLabel={(option: AssignedData) => option.name}
      getOptionValue={(option: AssignedData) => option.name}
      options={assigned}
      isClearable={true}
      backspaceRemovesValue={true}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 2,
        }),
      }}
    />
  )
}

export default AssignedSelectAndUpdate
