import { Box, Tooltip } from '@chakra-ui/react'

const TruncatedTextWithTooltip = ({ text }: { text: string }) => {
  const maxCharacterLimit = 20
  const isTextTooLong = text.length > maxCharacterLimit
  const displayText = isTextTooLong ? `${text.substring(0, maxCharacterLimit)}...` : text

  return (
    <Tooltip label={isTextTooLong ? text : ''} isDisabled={!isTextTooLong} children={<Box children={displayText} />} />
  )
}

export default TruncatedTextWithTooltip
