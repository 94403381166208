import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { projectServices } from '../../services/projects/index'
interface ProjectData {
  id: string
  name: string
}

interface ArrayObjectSelectState {
  selectedProject: ProjectData | null
  setSelectedProject: (project: ProjectData | null) => void
  id?: string
}

const ProjectSelect: React.FC<ArrayObjectSelectState> = ({ selectedProject, setSelectedProject, id }) => {
  const [projects, setProjects] = React.useState<ProjectData[]>([])

  useEffect(() => {
    const loadProjects = async () => {
      const response = await projectServices.findAll()
      if (response) {
        const projectData = response.data as ProjectData[]
        setProjects(
          projectData.map<ProjectData>((item) => {
            return {
              id: item.id,
              name: item.name,
            }
          }),
        )
        if (id) {
          const project = projectData.find((item) => item.id === id)
          if (project) setSelectedProject(project)
        }
      } else {
        toast('An error occurred while loading the Project data.')
      }
    }
    loadProjects()
  }, [id, setSelectedProject])

  return (
    <Select
      value={selectedProject}
      onChange={(option: ProjectData | null) => setSelectedProject(option)}
      getOptionLabel={(option: ProjectData) => option.name}
      getOptionValue={(option: ProjectData) => option.name}
      options={projects}
      isClearable={true}
      backspaceRemovesValue={true}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 3,
        }),
      }}
    />
  )
}

export default ProjectSelect
