import { Box, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { contentBankServices } from '../../../services/contentBank'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import ActionButton from '../../../components/customFormElements/ActionButtons'
import { useEffect, useState } from 'react'
import { contentTagServices } from '../../../services/contentTag'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import DefaultLayout from '../../../layouts/DefaultLayout'
import { FaImage } from 'react-icons/fa'
import NavigateCard from '../../../components/cards/navCard/NavigateCard'
import CustomModal from '../../../components/modal/CustomModal'
import ContentBankCreate from '../../contentTag/create'

const ContentDetail = () => {
  const [contentTagData, setContentTagData] = useState<any[]>([])
  const [contentId, setContentId] = useState<string>()
  const [contentUrl, setContentUrl] = useState<string>()
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure()

  const id = location.pathname.split('/content-bank/')[1]

  useEffect(() => {
    contentBankServices.findOne(id).then((response) => {
      setContentId(response.data.id)
      setContentUrl(response.data.mediaUrl)
    })

    contentTagServices.findAll(id, '', 1, 10).then((response) => {
      setContentTagData(response.data.items)
    })
  }, [])

  const deleteContentTag = (tagId: string) => {
    contentTagServices.remove(tagId).then(() => {
      window.location.reload()
    })
  }
  const handleDelete = () => {
    if (contentId) contentBankServices.remove(contentId)
  }

  if (!contentUrl) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavigateCard icon={FaImage} pageName='Content' secondButton='Add Content Tag' secondClickAction={onOpenAdd} />

      <Box position='relative' display='flex' justifyContent='center' mb={5}>
        <img src={contentUrl} alt={`Image ${contentId}`} style={{ display: 'block', maxWidth: '100%' }} />
      </Box>

      <GeneralCard title=''>
        <TableContainer maxH='20em' overflowY='auto'>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'Tag'} />
                <Th children={'Language'} />
                <Th children={'Created Date'} />
                <Th children={'Action'} />
              </Tr>
            </Thead>
            {contentTagData.map((item) => (
              <Tbody paddingBottom={'20px'} key={item.id}>
                <Tr>
                  <Td children={<Link children={item.tag} />} />
                  <Td children={item.tag} />
                  <Td children={item.tag.slice(0, 10)} />
                  <Td children={<ActionButton deleteFunction={() => deleteContentTag(item.id)} id={item.id} />} />
                </Tr>
              </Tbody>
            ))}
          </Table>
        </TableContainer>
      </GeneralCard>
      <CustomModal title='Add Content Tag' isOpen={isOpenAdd} onClose={onCloseAdd} children={<ContentBankCreate />} />
    </DefaultLayout>
  )
}

export default ContentDetail
