import React, { useEffect, useState } from 'react'

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { calendarServices } from '../../services/calendars/index'
import { useDisclosure } from '@chakra-ui/react'
import CustomModal from '../../components/modal/CustomModal'
import CalendarCreate from '../../pages/calendar/create'
import CalendarUpdate from '../../pages/calendar/update'

const localizer = momentLocalizer(moment)

interface CalendarEvent {
  id: string
  title: string
  start: Date
  end: Date
  color: string
  userName: string
}

const CalendarComponent: React.FC = () => {
  const { isOpen: isOpenEvent, onOpen: onOpenEvent, onClose: onCloseEvent } = useDisclosure()
  const { isOpen: isOpenEventUpdate, onOpen: onOpenEventUpdate, onClose: onCloseEventUpdate } = useDisclosure()
  const [events, setEvents] = useState<CalendarEvent[]>([])
  const [calendarId, setCalendarId] = useState<string | null>(null)

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null)

  useEffect(() => {
    const id = location.pathname.split('/influencer/')[1]
    if (id) {
      calendarServices.findByUserId(id).then((response) => {
        const mappedEvents: CalendarEvent[] = response.data.map((event: any) => ({
          id: event.id,
          title: event.title + ' ' + event.userName,
          start: new Date(event.startDate),
          end: new Date(event.endDate),
          color: event.statusColor,
        }))

        setEvents(mappedEvents)
      })
    } else {
      calendarServices.findAll().then((response) => {
        const mappedEvents: CalendarEvent[] = response.data.map((event: any) => ({
          id: event.id,
          title: event.title + ' ' + event.userName,
          start: new Date(event.startDate),
          end: new Date(event.endDate),
          color: event.statusColor,
        }))

        setEvents(mappedEvents)
      })
    }
  }, [])

  const handleSelectSlot = ({ start, end }: { start: Date; end: Date }) => {
    setSelectedStartDate(start)
    setSelectedEndDate(end)
    onOpenEvent()
  }

  const handleSelectEvent = (event: any) => {
    setCalendarId(event.id)
    onOpenEventUpdate()
  }

  return (
    <>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor='start'
        endAccessor='end'
        style={{ height: 600, margin: '50px', fontWeight: 'bold' }}
        eventPropGetter={(event) => {
          const backgroundColor = event.color || 'blue'
          return { style: { backgroundColor } }
        }}
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
      />
      <CustomModal
        title='Events Create'
        isOpen={isOpenEvent}
        onClose={onCloseEvent}
        key={'Event_Create'}
        children={<CalendarCreate startDate={selectedStartDate} endDate={selectedEndDate} />}
      />
      <CustomModal
        title='Events Update'
        isOpen={isOpenEventUpdate}
        onClose={onCloseEventUpdate}
        key={'Event_Update'}
        children={<CalendarUpdate id={calendarId ? calendarId : ''} />}
      />
    </>
  )
}

export default CalendarComponent
