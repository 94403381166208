import { Button, Input, Flex, Grid } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

// Services
import { calendarServices } from '../../../services/calendars'

// Components
import DateAndTimePicker from '../../../components/customFormElements/DateAndTimePicker'
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import SocialMediaSelect from '../../../components/selects/SocialMediaSelect'
import UserSelect from '../../../components/selects/UserSelect'
import CalendarStatusSelect from '../../../components/selects/CalendarStatusSelect'
import EnumSelect from '../../../components/selects/EnumSelect'
import { TaskType } from '../../../enums/task-type'
import { getEmployee as getEmployeeLocal } from '../../../utils/local-storage'

type CalendarFormData = {
  userId: string
  userName: string
  contentId: string
  title: string
  summary: string
  description: string
  taskType: string
  socialMediaType: string
  status: string
  startDate: Date
  endDate: Date
  employeeId: string
}

interface CalendarCreateProps {
  startDate: Date | null
  endDate: Date | null
}

const CalendarCreate: React.FC<CalendarCreateProps> = ({ startDate, endDate }) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null)
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<any | null>(null)
  const [selectedCalendarStatus, setSelectedCalendarStatus] = useState<any | null>(null)
  const [selectedTaskType, setSelectedTaskType] = useState<any | null>(null)
  const [user, setUser] = useState<any | null>(null)
  const employeeData = getEmployeeLocal()

  useEffect(() => {
    if (selectedStartDate === null) {
      setSelectedStartDate(startDate)
    }
    if (selectedStartDate === startDate) {
      setSelectedStartDate(startDate)
    }
    if (selectedEndDate === null) {
      setSelectedEndDate(endDate)
    }
    if (selectedEndDate === endDate) {
      setSelectedEndDate(endDate)
    }
  })

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    summary: Yup.string().required('Summary is required'),
    description: Yup.string().required('Description is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CalendarFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: CalendarFormData) => {
    if (!data.startDate && !selectedStartDate?.toLocaleString()) {
      toast('Start Date is required')
      return
    }
    if (!data.endDate && !selectedEndDate?.toLocaleString()) {
      toast('End Date is required')
      return
    }
    if (!data.socialMediaType && !selectedSocialMedia?.toLocaleString()) {
      toast('Social Media Type is required')
      return
    }
    if (!data.userId && !user.id.toLocaleString()) {
      toast('User is required')
      return
    }

    data.startDate = data.startDate ?? selectedStartDate?.toISOString()
    data.endDate = data.endDate ?? selectedEndDate?.toISOString()
    data.socialMediaType = selectedSocialMedia.name.toString()
    data.status = selectedCalendarStatus.toString()
    data.taskType = selectedTaskType.toString()
    data.employeeId = employeeData.sub.toString()
    data.userId = user.id.toString()
    data.userName = user.mainSocialMediaName.toString()

    calendarServices
      .create(data)
      .then((response) => {
        if (response) {
          window.location.reload()
        }
      })
      .catch((error) => {
        toast(error.request)
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            title='User'
            children={<UserSelect selectedUser={user} setSelectedUser={setUser} placeHolderText='User' id='user' />}
          />
          <CustomInputForm
            error={errors.title}
            title='Title'
            children={<Input type='text' id='title' {...register('title')} placeholder='Title' />}
          />
          <CustomInputForm
            error={errors.summary}
            title='Summary'
            children={<Input type='text' id='summary' {...register('summary')} placeholder='Summary' />}
          />
          <CustomInputForm
            error={errors.description}
            title='Description'
            children={<Input type='text' id='description' {...register('description')} placeholder='Description' />}
          />
          <CustomInputForm
            error={errors.taskType}
            title='Task Type'
            children={
              <EnumSelect
                selectedValue={selectedTaskType}
                selectValue={''}
                setSelectedValue={setSelectedTaskType}
                enumType={TaskType}
                placeholder='Task Type'
              />
            }
          />

          <CustomInputForm
            title='Select Social Media'
            children={
              <SocialMediaSelect
                selectedSocialMedia={selectedSocialMedia}
                setSelectedSocialMedia={setSelectedSocialMedia}
                selectSocialMedia={''}
              />
            }
          />
          <CustomInputForm
            error={errors.status}
            title='Status'
            children={
              <CalendarStatusSelect
                selectedCalendarStatus={selectedCalendarStatus}
                setSelectedCalendarStatus={setSelectedCalendarStatus}
              />
            }
          />
          <CustomInputForm
            customColSpan={1}
            error={errors.startDate}
            children={<DateAndTimePicker selectedDate={selectedStartDate} setSelectedDate={setSelectedStartDate} />}
            title='Start Date'
          />
          <CustomInputForm
            customColSpan={1}
            error={errors.endDate}
            children={<DateAndTimePicker selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate} />}
            title='End Date'
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Save'} />
      </Flex>
    </>
  )
}
export default CalendarCreate
