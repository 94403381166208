import { axiosInstance } from '../axios'

export const mailTemplateServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('mail-templates', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`mail-templates/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get(`mail-templates`)
  },
  findByProjectId: (projectId: string) => {
    return axiosInstance.get(`mail-templates/findByProjectId/${projectId}`)
  },
  findOne: (id: string) => {
    return axiosInstance.get(`mail-templates/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`mail-templates/${id}`)
  },
}
