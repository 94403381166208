import { axiosInstance } from '../../axios'

export const userNoteServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('user-notes', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`user-notes/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('user-notes')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`user-notes/${id}`)
  },
  findByUserId: (id: string) => {
    return axiosInstance.get(`user-notes/findByUserId/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`user-notes/${id}`)
  },
}
