export enum ContractStatus {
  Registered = 'registered',
  Signed = 'signed',
  NotSigned = 'notSigned',
  Cancelled = 'cancelled',
  Referred = 'referred',
  NewLead = 'newLead',
  Expired = 'expired',
  Special = 'special',
}
