import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { contentBankServices } from '../../services/contentBank'
import './Gallery.css'
import CustomModal from '../modal/CustomModal'
import { useDisclosure } from '@chakra-ui/react'
import ContentDetail from '../../pages/contentBank/detail'
import { useNavigate } from 'react-router-dom'

interface Image {
  id: string
  url: string
}

const GalleryComponent: React.FC = () => {
  const { isOpen: isOpenDetail, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure()
  const [images, setImages] = useState<Image[]>([])
  const [image, setImage] = useState<Image>()
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const navigate = useNavigate()
  const fetchImages = async (page: number) => {
    return contentBankServices.findAll('image', '', page, 20).then((response) => {
      return response.data.items.map((item: any) => ({
        id: item.id,
        url: item.mediaUrl,
      }))
    })
  }

  const loadImages = async () => {
    const newImages = await fetchImages(page)

    setImages((prevImages) => {
      const newUniqueImages = newImages.filter(
        (newImage: Image) => !prevImages.some((prevImage: Image) => prevImage.id === newImage.id),
      )
      return [...prevImages, ...newUniqueImages]
    })

    if (newImages.length === 0) {
      setHasMore(false)
    }
    setPage((prevPage) => prevPage + 1)
  }

  useEffect(() => {
    loadImages()
  }, [])

  const openModel = (image: Image) => {
    navigate(`/content-bank/${image.id}`)
    setImage(image)
  }

  return (
    <InfiniteScroll dataLength={images.length} next={loadImages} hasMore={hasMore} loader={''}>
      <div className='gallery'>
        {images.map((image) => (
          <div className='frame' key={image.id}>
            <img
              src={image.url}
              alt={`${image.id}`}
              onClick={() => {
                openModel(image)
              }}
            />
          </div>
        ))}
      </div>
    </InfiniteScroll>
  )
}

export default GalleryComponent
