import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { roleServices } from '../../services/employees/roles/index'
interface RoleData {
  id: string
  name: string
}

interface ArrayObjectSelectState {
  selectedRole: RoleData | null
  setSelectedRole: (role: RoleData | null) => void
  id: string
  placeHolderText?: string
}

const RoleSelect: React.FC<ArrayObjectSelectState> = ({ selectedRole, setSelectedRole, id, placeHolderText }) => {
  const [roles, setRole] = useState<RoleData[]>([])

  useEffect(() => {
    const loadRoles = async () => {
      // const response = await roleServices.findByEmployeeId(id)
      const response = await roleServices.findAll()

      if (response) {
        const roleData = response.data as RoleData[]
        setRole(
          roleData.map<RoleData>((item) => {
            return {
              id: item.id,
              name: item.name,
            }
          }),
        )
        if (id) {
          const role = roleData.find((item) => item.id === id)
          if (role) setSelectedRole(role)
        }
      } else {
        toast('An error occurred while loading the Role data.')
      }
    }
    loadRoles()
  }, [id, setSelectedRole])

  return (
    <Select
      value={selectedRole}
      onChange={(option: RoleData | null) => {
        setSelectedRole(option)
      }}
      getOptionLabel={(option: RoleData) => option.name}
      getOptionValue={(option: RoleData) => option.id}
      options={roles}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={placeHolderText}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 3,
        }),
      }}
    />
  )
}

export default RoleSelect
