import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { toast } from 'react-toastify'
import React, { useState } from 'react'

// Services
import { contentBankServices } from '../../../services/contentBank'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import EnumSelect from '../../../components/selects/EnumSelect'
import { ContentType } from '../../../enums/content-type'
import ProjectSelect from '../../../components/selects/ProjectSelect'

const ContentAdd: React.FC = () => {
  const [selectedContentType, setSelectedContentType] = useState<any | null>(null)
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null) // Yeni state

  const onSubmit = async () => {
    if (!selectedProject?.id) {
      toast('Project Id is required')
      return
    }
    if (!selectedContentType) {
      toast('Content Type is required')
      return
    }
    if (!selectedFile) {
      toast('Please select an image file to upload')
      return
    }

    const projectId = selectedProject.id
    const mediaType = selectedContentType.toString()

    const formData = new FormData()
    formData.append('file', selectedFile)

    try {
      const response = await contentBankServices.create(mediaType, projectId, formData)
      if (response) {
        toast('File uploaded successfully!')
        window.location.reload()
      }
    } catch (error: any) {
      toast(error.response?.data?.message || 'Upload failed!')
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0])
    }
  }

  return (
    <>
      <form>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            title='Content Type'
            children={
              <EnumSelect
                selectValue={'image'}
                selectedValue={selectedContentType}
                setSelectedValue={setSelectedContentType}
                enumType={ContentType}
              />
            }
          />
          <CustomInputForm
            title='Project'
            children={<ProjectSelect selectedProject={selectedProject} setSelectedProject={setSelectedProject} />}
          />
          <CustomInputForm
            customColSpan={4}
            title='Image Upload'
            children={<Input type='file' accept='image/*' onChange={handleFileChange} />}
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={onSubmit}>
          Save
        </Button>
      </Flex>
    </>
  )
}

export default ContentAdd
