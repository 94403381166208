import { useEffect, useState } from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import { Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import NavigateCard from '../../components/cards/navCard/NavigateCard'
import { AiOutlineLink } from 'react-icons/ai'
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import ActionButton from '../../components/customFormElements/ActionButtons'
import { contentTagServices } from '../../services/contentTag'

const ContentTag = () => {
  const [contentTagData, setContentTagData] = useState<any[]>([])
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure()

  useEffect(() => {
    contentTagServices.findAll('', '', 1, 10).then((response) => {
      setContentTagData(response.data.items)
    })
  }, [])

  const deleteContentTag = (tagId: string) => {
    contentTagServices.remove(tagId).then(() => {
      window.location.reload()
    })
  }

  return (
    <DefaultLayout>
      <NavigateCard
        icon={AiOutlineLink}
        pageName='Content Tag'
        secondButton='Add Content Tag'
        secondClickAction={onOpenAdd}
      />
      <GeneralCard title=''>
        <TableContainer maxH='20em' overflowY='auto'>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'Tag'} />
                <Th children={'Language'} />
                <Th children={'Created Date'} />
                <Th children={'Action'} />
              </Tr>
            </Thead>
            {contentTagData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td children={<Link children={item.tag} />} />
                    <Td children={item.tag} />
                    <Td children={item.tag.slice(0, 10)} />
                    <Td children={<ActionButton deleteFunction={() => deleteContentTag(item.id)} id={item.id} />} />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default ContentTag
