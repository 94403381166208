import { Box, Grid } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

// Services
import { userPhoneServices } from '../../../../services/users/userPhone/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import DetailCard from '../../../../components/cards/detailCard/DetailCard'

const UserPhoneDetail = (props: { id: string }) => {
  const [userPhoneData, setUserPhoneData] = useState<any | null>(null)

  useEffect(() => {
    userPhoneServices.findOne(props.id).then((response) => {
      setUserPhoneData(response.data)
    })
  }, [])

  if (!userPhoneData) {
    return <LoadingComponent />
  }

  return (
    <Box>
      <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
        <CustomInputForm children={<DetailCard upperText='Country' lowerText={userPhoneData.country} />} />
        <CustomInputForm children={<DetailCard upperText='Post Code' lowerText={userPhoneData.phoneNumber} />} />
      </Grid>
    </Box>
  )
}
export default UserPhoneDetail
//
