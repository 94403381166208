import { axiosInstance, axiosInstanceForContractSign } from '../../axios'

export const userContractServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('user-contracts', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstanceForContractSign.put(`user-contracts/sign/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('user-contracts')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`user-contracts/${id}`)
  },
  findByUserId: (id: string) => {
    return axiosInstance.get(`user-contracts/findByUserId/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`user-contracts/${id}`)
  },
  fillOutTemplateContract: (id: string) => {
    return axiosInstance.post(`user-contracts/fillOutTemplate/${id}`)
  },
}

export const contractSignServices = {
  sign: (token: string, requestModel: any) => {
    return axiosInstanceForContractSign.put(`user-contracts/sign/${token}`, requestModel)
  },
  reject: (token: string) => {
    return axiosInstanceForContractSign.put(`user-contracts/reject/${token}`)
  },
  fillOutTemplateContract: (token: string) => {
    return axiosInstanceForContractSign.get(`user-contracts/fillOutTemplateContract/${token}`)
  },
  fillOutTemplateNda: (token: string) => {
    return axiosInstanceForContractSign.get(`user-contracts/fillOutTemplateNda/${token}`)
  },
  findContractLevel: (token: string) => {
    return axiosInstanceForContractSign.get(`user-contracts/findContractLevel/${token}`)
  },
}
