import { Button, Flex, Grid } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useState } from 'react'

// Services
import { employeeRoleServices } from '../../../services/employees/employeeRoles/index'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'

import RoleSelect from '../../../components/selects/RoleSelect'

type EmployeeFormData = {
  employeeId: string
  roleId: string
  roleTitle: string
}

const EmployeeRoleCreate = (props: { employeeId: string }) => {
  const [selectedRole, setSelectedRole] = useState<any | null>(null)

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<EmployeeFormData>()

  const onSubmit = (data: EmployeeFormData) => {
    if (!selectedRole) {
      toast('Type is required')
      return
    }
    data.employeeId = props.employeeId
    data.roleId = selectedRole.id
    data.roleTitle = selectedRole.name

    employeeRoleServices
      .create(data)
      .then((response) => {
        if (response) {
          window.location.reload()
        }
      })
      .catch((error) => {})
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(2, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            title='Role'
            children={
              <RoleSelect selectedRole={selectedRole} setSelectedRole={setSelectedRole} id={props.employeeId} />
            }
          />
        </Grid>
      </form>
      <Flex margin={'0px 0px 0px 20px'}>
        <Button colorScheme='red' size={'md'} minWidth={'120px'} onClick={() => handleOnSubmit()} children={'Add'} />
      </Flex>
    </>
  )
}

export default EmployeeRoleCreate
