export const styles = {
  grid: {
    border: '1px',
    borderRadius: '2xl',
    borderColor: '#D9D9D9',
    padding: '10px',
    bg: 'white',
    cursor: 'pointer',
  },
  image: {
    paddingTop: '10px',
    maxHeight: '90%',
    maxWidth: '90%',
  },
  text: {
    fontSize: '12px',
    color: '#000000',
    paddingLeft: '20px',
  },
  value: {
    fontSize: '24px',
    color: '#000000',
    fontWeight: 700,
    paddingLeft: '20px',
  },
}
