import { GridItem, Button, Grid, Box } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useState } from 'react'

// Services
import { userContractServices } from '../../../../services/users/userContract/index'

// Components
import ContractTemplateSelect from '../../../../components/selects/ContractTemplateSelect'
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'

type UserContractFormData = {
  userId: string
  contractTemplateId: string
  contractLevelId: string
  postingDay: string
}

const UserContractCreate = () => {
  const [selectedContractTemplate, setSelectedContractTemplate] = useState<any | null>(null)
  const userId = location.pathname.split('/influencer/')[1]

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<UserContractFormData>({})

  const onSubmit = (data: UserContractFormData) => {
    data.userId = userId
    data.contractTemplateId = selectedContractTemplate.id

    userContractServices.create(data).finally(() => {
      window.location.reload()
    })
  }

  return (
    <>
      <form onSubmit={handleOnSubmit}>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            title='Contract Template'
            children={
              <ContractTemplateSelect
                selectedContractTemplate={selectedContractTemplate}
                setSelectedContractTemplate={setSelectedContractTemplate}
                selectContractTemplate=''
              />
            }
          />
          <GridItem colSpan={2} />
          <Box mt={5}>
            <CustomInputForm
              customColSpan={1}
              children={<Button colorScheme='red' size={'sm'} type={'submit'} children={'Save'} />}
            />
          </Box>
        </Grid>
      </form>

      <Box
        width='100%'
        height='100%'
        overflow='auto'
        dangerouslySetInnerHTML={{
          __html: selectedContractTemplate ? selectedContractTemplate.content : '',
        }}
      />
    </>
  )
}
export default UserContractCreate
