import { axiosInstance } from '../../axios/index'

export const roleServices = {
  findAll: () => {
    return axiosInstance.get(`employee/roles`)
  },
  findByEmployeeId: (id: string) => {
    return axiosInstance.get(`employee/roles/findByEmployeeId/${id}`)
  },
  findOne: (id: string) => {
    return axiosInstance.get(`employee/roles/${id}`)
  },
}
