import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { FaLanguage } from 'react-icons/fa'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { languageServices } from '../../../services/languages/index'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import CustomCheckbox from '../../../components/customFormElements/CustomCheckbox'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'

type LanguageFormData = {
  name: string
  code: string
  flag: string
  rtl: boolean
  orderNumber: number
}

const LanguageUpdate = () => {
  const [languageData, setLanguageData] = useState<any | null>(null)
  const [isChecked, setChecked] = useState(false)
  const navigate = useNavigate()

  const id = location.pathname.split('/language/update/')[1]

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.string().required('Code is required'),
    flag: Yup.string().required('Flag is required'),
    rtl: Yup.boolean().required('Language is required'),
    orderNumber: Yup.number().required('Orner Number is required'),
  })

  useEffect(() => {
    languageServices.findOne(id).then((response) => {
      if (response) {
        setLanguageData(response.data)
        setChecked(response.data.rtl)
      }
    })
  }, [])

  const handleCheckChange = (newCheckState: boolean) => {
    setChecked(newCheckState)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLanguageData({
      ...languageData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LanguageFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: LanguageFormData) => {
    languageServices.update(id, data).finally(() => {
      navigate('/language')
    })
  }

  if (!languageData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={FaLanguage} pageName='Language' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Update Language'>
          <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              title='Name'
              children={
                <Input
                  type='text'
                  id='name'
                  {...register('name')}
                  value={languageData.name}
                  onChange={handleInputChange}
                />
              }
              error={errors.name}
            />
            <CustomInputForm
              title='Code'
              children={
                <Input
                  type='text'
                  id='code'
                  {...register('code')}
                  value={languageData.code}
                  onChange={handleInputChange}
                />
              }
              error={errors.name}
            />
            <CustomInputForm
              title='Flag'
              children={
                <Input
                  type='text'
                  id='flag'
                  {...register('flag')}
                  value={languageData.flag}
                  onChange={handleInputChange}
                />
              }
              error={errors.name}
            />

            <CustomInputForm
              title='Order Number'
              children={
                <Input
                  type='number'
                  id='orderNumber'
                  {...register('orderNumber')}
                  value={languageData.orderNumber}
                  onChange={handleInputChange}
                />
              }
              error={errors.name}
            />
            <CustomInputForm
              children={
                <CustomCheckbox
                  defaultChecked={isChecked}
                  onCheckChange={handleCheckChange}
                  name={'RTL'}
                  register={register}
                  registerName='rtl'
                />
              }
              error={errors.name}
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </DefaultLayout>
  )
}
export default LanguageUpdate
