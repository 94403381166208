import { useEffect, useState } from 'react'
import { Grid } from '@chakra-ui/react'

// Services
import { userNoteServices } from '../../../../services/users/userNote/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import DetailCard from '../../../../components/cards/detailCard/DetailCard'

const UserNoteDetail = (props: { id: string }) => {
  const [userNoteData, setUserNoteData] = useState<any | null>(null)

  useEffect(() => {
    userNoteServices.findOne(props.id).then((response) => {
      setUserNoteData(response.data)
    })
  }, [])

  if (!userNoteData) {
    return <LoadingComponent />
  }

  return (
    <>
      <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(4, 1fr)' gap={6} margin={'20px'}>
        <CustomInputForm children={<DetailCard upperText='Title' lowerText={userNoteData.title} />} />
        <CustomInputForm children={<DetailCard upperText='Description' lowerText={userNoteData.description} />} />
        <CustomInputForm children={<DetailCard upperText='Note Type' lowerText={userNoteData.noteType} />} />
      </Grid>
    </>
  )
}
export default UserNoteDetail
//
