import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { MdWork } from 'react-icons/md'
import { toast } from 'react-toastify'

import DefaultLayout from '../../layouts/DefaultLayout'

// Services
import { projectServices } from '../../services/projects/index'

// Components
import ActionButton from '../../components/customFormElements/ActionButtons'
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import NavCard from '../../components/cards/navCard/NavigateCard'

const Project = () => {
  const [projectData, setProjectsData] = useState<any[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    projectServices.findAll().then((response: any) => {
      if (response) setProjectsData(response.data)
      else toast('An error occurred while loading the Project data.')
    })
  }, [])

  const updateProject = (id: string) => {
    navigate(`/project/update/${id}`)
  }

  const detailProject = (id: string) => {
    navigate(`/project/${id}`)
  }

  const deleteProject = (id: string) => {
    projectServices.remove(id).finally(() => {
      window.location.reload()
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdWork} pageName='Project' secondButton='Add Project' secondButtonLink='add' />
      <GeneralCard title='Project List' customColumn={{ base: 1, md: 1, lg: 1, '2xl': 1 }}>
        <TableContainer>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'NAME'} />
                <Th children={'COTITLEDE'} />
                <Th children={'NUMBER'} />
                <Th children={'PERSON'} />
                <Th children={'EMAIL'} />
                <Th children={'ACTION'} />
              </Tr>
            </Thead>
            {projectData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td onClick={() => detailProject(item.id)} cursor={'pointer'} children={item.name} />
                    <Td children={item.title} />
                    <Td children={item.number} />
                    <Td children={item.person} />
                    <Td children={item.email} />
                    <Td
                      children={
                        <ActionButton deleteFunction={deleteProject} id={item.id} updateFunction={updateProject} />
                      }
                    />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default Project
