import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import UserFileDetail from './detail'

// Services
import { userFileServices } from '../../../services/users/userFile/index'

// Components
import TruncatedTextWithTooltip from '../../../components/customFormElements/TruncatedTextWithTooltip'
import ActionButton from '../../../components/customFormElements/ActionButtons'
import LoadingComponent from '../../../components/shareds/LoadingComponent'
import CustomModal from '../../../components/modal/CustomModal'

const UserFile = () => {
  const [userFileData, setUserFilesData] = useState<any[]>([])
  const [userFileId, setUserFileId] = useState<string>()

  const { isOpen: isOpenDetail, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure()

  const id = location.pathname.split('/influencer/')[1]

  useEffect(() => {
    userFileServices.findByUserId(id).then((response) => {
      if (response) {
        setUserFilesData(response.data)
      } else {
        toast('An error occurred while loading the User File data.')
      }
    })
  }, [id])

  const setUserFileFunction = () => {
    userFileServices.findByUserId(id).then((response) => {
      if (response) {
        setUserFilesData(response.data)
      } else {
        toast('An error occurred while loading the User File data.')
      }
    })
  }

  const detailFunction = (fileId: string) => {
    setUserFileId(fileId)
    onOpenDetail()
  }

  const deleteUserFile = (id: string) => {
    userFileServices.remove(id).finally(() => {
      setUserFileFunction()
    })
  }

  if (!userFileData) {
    return <LoadingComponent />
  }

  return (
    <>
      <TableContainer maxH='20em' overflowY='auto'>
        <Table variant='simple' size={'sm'}>
          <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
            <Tr>
              <Th children={'File Type'} />
              <Th children={'File Name'} />
              <Th children={'URL'} />
              <Th children={'Created Date'} />
              <Th children={'Action'} />
            </Tr>
          </Thead>
          {userFileData.map((item) => {
            return (
              <Tbody paddingBottom={'20px'} key={item.id}>
                <Tr>
                  <Td
                    onClick={() => detailFunction(item.id)}
                    cursor={'pointer'}
                    color={'blue'}
                    children={<Link children={item.fileType} />}
                  />
                  <Td children={<TruncatedTextWithTooltip text={item.fileName} />} />
                  <Td children={<Link children={<TruncatedTextWithTooltip text={item.fileUrl} />} />} />
                  <Td children={item.createdDate.slice(0, 10)} />
                  <Td children={<ActionButton deleteFunction={deleteUserFile} id={item.id} />} />
                </Tr>
              </Tbody>
            )
          })}
        </Table>
      </TableContainer>
      {
        <CustomModal
          title='User File Detail'
          isOpen={isOpenDetail}
          onClose={onCloseDetail}
          key={'User_File_Detail'}
          children={<UserFileDetail id={userFileId ? userFileId : ''} />}
        />
      }
    </>
  )
}

export default UserFile
