import React, { useEffect } from 'react'
import Select from 'react-select'

import { ContractTemplateType } from '../../enums/contract-template-type'

interface ArrayObjectSelectState {
  selectedContractTemplateType: ContractTemplateType | null
  setSelectedContractTemplateType: (contractTemplateType: ContractTemplateType | null) => void
  type?: string
}

const ContractTemplateTypeSelect: React.FC<ArrayObjectSelectState> = ({
  selectedContractTemplateType,
  setSelectedContractTemplateType,
  type,
}) => {
  const [contractTemplateTypes, setContractTemplateTypes] = React.useState<ContractTemplateType[]>([])

  useEffect(() => {
    setContractTemplateTypes(Object.values(ContractTemplateType))

    if (type) {
      const contractTemplateType = contractTemplateTypes.find((item) => item === type)

      if (contractTemplateType) setSelectedContractTemplateType(contractTemplateType)
    }
  }, [type, setContractTemplateTypes])

  return (
    <Select
      value={
        selectedContractTemplateType
          ? {
              value: selectedContractTemplateType,
              label: selectedContractTemplateType?.toUpperCase(),
            }
          : null
      }
      onChange={(option: any) => {
        setSelectedContractTemplateType(option ? option.value : null)
      }}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      options={contractTemplateTypes.map((type) => ({
        value: type,
        label: type.toUpperCase(),
      }))}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'TYPE'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 3,
        }),
      }}
    />
  )
}

export default ContractTemplateTypeSelect
