import { Button, Input, Flex, Grid, Box } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useState } from 'react'
import * as Yup from 'yup'

// Services
import { userSocialServices } from '../../../../services/users/userSocial/index'

// Components
import SocialMediaSelect from '../../../../components/selects/SocialMediaSelect'
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'

type UserSocialFormData = {
  userId: string
  socialMediaName: string
  socialMediaId: string
  profileLink: string
  socialAccountName: string
  followerNumber: number
}

const UserSocialCreate = () => {
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<any | null>(null)
  const id = location.pathname.split('/influencer/')[1]

  const validationSchema = Yup.object().shape({
    socialAccountName: Yup.string().required('Social Account Name is required'),
    profileLink: Yup.string().required('Profile Link is required'),
    followerNumber: Yup.number().required('Follower Number is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserSocialFormData>({
    resolver: yupResolver(validationSchema) as any,
  })

  const onSubmit = (data: UserSocialFormData) => {
    if (!selectedSocialMedia) {
      toast('Social Media is required')
      return
    }

    data.socialMediaId = selectedSocialMedia.id
    data.socialMediaName = selectedSocialMedia.name.toLowerCase()
    data.userId = id

    userSocialServices.create(data).finally(() => {
      window.location.reload()
    })
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            title='Social Media'
            children={
              <SocialMediaSelect
                selectedSocialMedia={selectedSocialMedia}
                setSelectedSocialMedia={setSelectedSocialMedia}
                selectSocialMedia={''}
              />
            }
          />
          <CustomInputForm
            title='Profile Link'
            children={<Input type='text' id='profileLink' {...register('profileLink')} />}
          />
          <CustomInputForm
            title='Account Name'
            children={<Input type='text' id='socialAccountName' {...register('socialAccountName')} />}
          />
          <CustomInputForm
            title='Follower Number'
            children={<Input type='number' id='followerNumber' {...register('followerNumber')} />}
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Save'} />
      </Flex>
    </Box>
  )
}
export default UserSocialCreate
