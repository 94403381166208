import { axiosInstance } from '../../axios'

export const userEmailServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('user-emails', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`user-emails/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('user-emails')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`user-emails/${id}`)
  },
  findByUserId: (id: string) => {
    return axiosInstance.get(`user-emails/findByUserId/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`user-emails/${id}`)
  },
}
