import { Flex, FlexProps, Icon, Link } from '@chakra-ui/react'
import { IconType } from 'react-icons'

interface SideBarItemProps extends FlexProps {
  icon: IconType
  to: string
  children: React.ReactText
  p?: number
  mx?: number
  fontSize?: number
  iconSize?: number
}

const SideBarItem = ({
  icon,
  to,
  children,
  p = 2,
  mx = 4,
  fontSize = 16,
  iconSize = 26,
  ...rest
}: SideBarItemProps) => {
  return (
    <Link href={to} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align='center'
        p={p}
        mx={mx}
        fontSize={fontSize}
        borderRadius='lg'
        borderBottom={''}
        role='group'
        cursor='pointer'
        _hover={{
          bg: 'red.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr='4'
            fontSize={iconSize}
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  )
}

export default SideBarItem
