import { axiosInstance } from '../../axios'

export const userFileServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('user-files', requestModel)
  },
  findAll: () => {
    return axiosInstance.get('user-files')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`user-files/${id}`)
  },
  findByUserId: (id: string) => {
    return axiosInstance.get(`user-files/findByUserId/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`user-files/${id}`)
  },
}
