import axios from 'axios'
import { getToken as getTokenLocal } from '../../utils/local-storage'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'

export const axiosInstance = axios.create({
  // baseURL: 'http://localhost:8080/',
  baseURL: 'https://socialcrm.livingunderwear.com/',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getTokenLocal()}`,
  },
})

axiosInstance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response
  },
  (error) => {
    toast(error.response.data.message)
    // Do something with response error
    // You can even test for specific status codes here

    // Return the error to the caller
    return Promise.reject(error)
  },
)

export const axiosInstanceForContractSign = axios.create({
  baseURL: 'https://newcrmapi.domainfortesting.uk/',
  timeout: 10000,
})

axiosInstanceForContractSign.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    toast(error.response.data.message)

    return Promise.reject(error)
  },
)

export const axiosInstanceForMedia = axios.create({
  baseURL: 'https://socialcrm.livingunderwear.com/',
  timeout: 5000,
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${getTokenLocal()}`,
  },
})

axiosInstanceForMedia.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    toast(error.response.data.message)

    return Promise.reject(error)
  },
)
