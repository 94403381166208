import { axiosInstance } from '../../axios'

export const employeeRoleServices = {
  create: (requestModel: any) => {
    return axiosInstance.post(`employee/employee-roles/`, requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`employee/employee-roles/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get(`employee/employee-roles/`)
  },
  findEmployeeById: (id: string) => {
    return axiosInstance.get(`employee/employee-roles/findByEmployeeId/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`employee/employee-roles/${id}`)
  },
}
