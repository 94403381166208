import DefaultLayout from '../../layouts/DefaultLayout'
import { FaCalendarAlt } from 'react-icons/fa'

// Components
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import NavCard from '../../components/cards/navCard/NavigateCard'
import CalendarComponent from '../../components/calendar'

const Calendar = () => {
  return (
    <DefaultLayout>
      <NavCard icon={FaCalendarAlt} pageName='Calendar' secondButton='' secondButtonLink='' />
      <GeneralCard title=''>
        <CalendarComponent />
      </GeneralCard>
    </DefaultLayout>
  )
}

export default Calendar
